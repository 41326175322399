/******************************
*
*	Component: Buttons
*
******************************/

.btn-float-top-right {
	position: absolute;
	top: 10px;
	right: 20px;
	font-size: 3em;
}

button {
  &.btn-help {
    border-radius: 50%;
    background-color: #03a9f4;
    color: #FFF;
    height:25px;
    width:25px;
    overflow: hidden;
    font-size: 16px;
    line-height: 24px;
    padding: 0px;
  }
  &:focus {
    outline: none!important;
  }
}

img[data-image] {
  cursor: pointer;
}

.img-thumbnail {
  padding:1px;
  border: 0;
}

.artist-browse {
  margin: 0 0 10px 0;
  padding: 0;
	li {
    display: inline-block; 
    cursor: pointer;
		a {
      border-radius: 3px;
      display: inline-block;
      width: 20px;
      font-size: 13px;
      font-weight: 600;
      height: 20px;
      &.active { 
        background-color: $brand-primary; color: #FFF;
      }
      &:hover {
        background-color: #dedede;
      }
		}
	}
}


.month_text, .year_text {
  min-width: 80px;
  display: inline-block;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}

.lyrics {
  padding: 3px 5px;
  font-size: 8px;
  border: 1px solid #9b9b9b;
  border-radius: 3px;
  font-weight: 900;
  color: #9b9b9b;
}

.btn { 
   white-space: initial;
   font-size: .85rem;
   font-weight: 500;
   padding: 0.275rem 1rem;
   &-primary, &-secondary {
    border-radius: 1.5rem;
   }
   &-copy {
     border-radius: 100%;
     border: 1px solid #d4d4d4;
     font-size: 10px;
     cursor: pointer;
     margin: 0 0 0 5px;
     height: 30px;
     width: 30px;
     text-align: center;
     &:hover {
      filter: brightness(95%);
     }
   }
}

.play-btn {
  position: absolute;
  border-radius: 100%;
  height: 40px;
  width: 40px;
  text-align: center;
  color: #ffffff;
  background: rgba(50,50,50,0.8);
  border: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: auto;
  cursor: pointer;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  &:hover {
    background: rgba(0,0,0,0.8);
    -webkit-transition: background-color .3s ease;
    -moz-transition: background-color .3s ease;
    -o-transition: background-color .3s ease;
    transition: background-color .3s ease;
  }
}

.dropdown-menu.options {
  margin: 0;
  padding: 0;

  .dropdown-item {
    color: #5a5a5a;
    font-size: 0.9em;
    padding: 10px 15px!important;
    border-top: 1px solid #f4f4f4;
    .dropdown-icon {
      width: 20px;
      text-align: center;
      margin: 4px 10px 0 0;
      float: left;
    }
    .dropdown-link {
      display: block;
      margin-left: 30px;
      font-size: 12px;
      color: #888888;
      a {
        font-weight: initial;
        color: #888888;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 200px;
      }
      .btn-sm.btn-copy {
        height: 20px;
        width: 20px;
        font-size: 8px;
        padding: 0;
      }
    }
    a {
      display: block!important;
      font-weight: 500;
    }
    &.active, &:active {
      color: initial;
      background-color: #f4f4f4;
      border-radius: 5px;
    }
  }
}

.safe-delete-yes {
  background: $brand-primary!important;
  border-color: $brand-primary!important;
}

.safe-delete-no {
  background-color: #6c757d!important;
  border-color: #6c757d!important;
}

.btn-round {
  display: inline-block;
  background-color: #d4d4d4;
  height: 20px;
  width: 20px;
  line-height: 20px;
  border-radius: 50%;
  border: none;
  margin: 0 0 0 5px;
  font-size: 13px;
  font-weight: 600;
  color: #555555;
  text-align: center;
  padding: 0; 
}

.btn-round-preview {
  border-radius: 50%;
}

.btn-primary, .btn-primary:hover {
  background: $brand-primary!important;
  border: 2px solid $brand-primary!important;
}

#editor_save span {
  pointer-events: none;
}