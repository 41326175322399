#page_menu {
  transition: left 0.1s ease;
  z-index: 20;
  position: fixed;
  margin-top: $header_height;
  top: 0;
  left: -240px;
  bottom: 0;
  width: $menu_width;
  padding-top: 20px;
  padding-bottom: 80px;
  height: auto!important;
  overflow-x: hidden;
  overflow-y: auto;
  background: #1a1a1a;
  -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,.16);
  box-shadow: inset 0 0 3px rgba(0,0,0,.16);

  hr {
    margin: 0px;
  }

  .menu-header {
    padding: 8px!important;
    display: block;
    padding-left: 20px;
    color: #c2c2c2!important;
    font-weight: 500;
    transition: background-color 0.3s ease-in-out, color 0.1s ease-in-out;
    font-size: 15px;
    border-radius: 3px;
    margin: 8px;

    span {
      &.fa {
        margin-right: 5px;
      }
    }

    .chevron {
      transition: transform 0.2s ease;
      transform: rotate(180deg);
      line-height: 27px;
      font-size: 12px;
    }

    &.collapsed {
      border-left: none;
      background-color: transparent;
      color: #c2c2c2 !important;
      a {
        color: #c2c2c2 !important;
        transition: background-color 0.3s ease-in-out, color 0.01s ease-in-out;
      }
    }

    &:hover {
      color: #ffffff !important;
      text-decoration: none;

      a {
        color: #ffffff !important;
        text-decoration: none;
      }

      .chevron {
        display: inline-block;
      }
    }

    &.active {
      color: #ffffff!important;
    }

    &.title {
      color: #ffffff!important;
    }
  }

  &.show {
    left: 0;

    #page_header {
      left: 0px;
    }
  }

  .wrapper {
    width: $menu_width;
  }

  .menu-admin {
    margin: 30px 0;
    border-top: 1px solid rgba(250, 250, 250, .2);
    padding: 10px 0;
  }
 
  .menu-icon {
    text-align: center;
    width: 19px;
  }

  nav {
    font-size: 87%;
    margin: -10px 8px 15px;
    border-radius: 3px;

    .nav-link {
      color: #c2c2c2;
      border-radius: 3px;
      padding: 6px 9px;
      margin: 0 6px 0px 33px;
      font-weight: 500;
      -webkit-transition: background-color .5s ease-out;
      -moz-transition: background-color .5s ease-out;
      -o-transition: background-color .5s ease-out;
      transition: background-color .5s ease-out;
      span {
        padding-right: 5px;
      }

      &:hover {
        color: #ffffff;
      }

      &.active {
        background: transparent;
        color: #ffffff;
        font-weight: 700;
        span {
          color: #ffffff;
          &:before {
            color: #ffffff;
          }
        }
      }
    }
  }
}

#menu-toggle {
  color: #ffffff;
  background: none;
  font-size: 1.8em;
  border-radius: 0;
  padding: 5px 12px;
  height: $header_height;
  width: 45px;
  transition: left 0.1s ease;
  position: fixed;
  left: 0px;
  z-index: 30;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 17px;
  border: none;
}

#menu-toggle .bar {
  width: 25px;
  height: 2px;
  background-color: #FFF;
  border-radius: 4px;
  transition: all 0.3s ease;
}

#menu-toggle .top {
  transform-origin: left center;
}

#menu-toggle .bottom {
  transform-origin: right center;
}

#menu-toggle.open .top {
  transform: rotate(45deg) translateY(-7px) translateX(0px);
}

#menu-toggle.open .bottom {
  transform: rotate(-45deg) translateY(-12px) translateX(9px);
}

#menu-toggle.open {
  left: 0px;
}