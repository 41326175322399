/******************************
*
*	Component: Tables
*
******************************/

table {
  font-size:.9rem;
}
table th {
  font-weight: 700;
  font-size: 11px;
  text-transform: uppercase;
}

tr.no-records-found {
  font-size: .75rem;
  font-weight: 500;
}

table.small td form {
  font-size: 115%;

  input[type=checkbox] {
    margin-left: -20px !important;
    margin-top: 4px !important;
  }

  .input-group {
    width: 100%;
  }
}

tr.inactive>td {
  opacity: (0.75) !important;
}

tbody tr.highlight td,
tbody tr.inactive.highlight td {
  background-color: #eff5f9!important;
  background: #eff5f9!important;
}

.table th {
  border-top: none;

  &.image {
    width: 60px;
  }
}

.bootstrap-table .disabled {
  color: rgba(0, 0, 0, 0.5) !important;
}

.bootstrap-table .search {
  width: 100%;
}


.bootstrap-table td.wide {
  width: 100%;
}

.bootstrap-table td.wide-80 {
  width: 80%;
}

.bootstrap-table .progress {
  height: 10px;
  width: 50px;
  margin: 0px auto;
  vertical-align: middle;
}

.bootstrap-table .tab-pane {
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.24);
  margin-bottom: 20px;
  border-radius: 7px;
}

td div.album-details {
  line-height: 1.1em;
}

.fixed-table-loading {
  z-index: 1;
  background-color: #f1f2f2;
  font-size: .75rem;
  padding: 7px 0 0px;
}

.progress-title {
  margin-left: 5px;
  font-size: 60%;
  color: #A7A7A7;

}

th:focus {
  outline: none;
}

td {
  vertical-align: middle !important;
}

.bootstrap-datetimepicker-widget {
  min-width: 260px!important;
  font-size: 14px!important;
  &.dropdown-menu {
    overflow: hidden!important;
    padding: 0!important;
  }
  table {
    background-color: #ffffff;
    tbody {
      background-color: #ffffff!important;
    }
    tr {
      background-color:#ffffff!important;
    }
    td {
      background-color:#ffffff;
    }
    td,
    th {
      border: none !important;
    }
  }
}

.nowrap {
  white-space: nowrap;
}

.bootstrap-datetimepicker-widget table td.active, .bootstrap-datetimepicker-widget table td.active:hover {
  background-color: #007bff!important;
}

.bootstrap-datetimepicker-widget table td.active {
  color: #ffffff!important;
}

.bootstrap-datetimepicker-widget table td.active.today:before {
  border-bottom-color: #fff!important;
}

.bootstrap-datetimepicker-widget table td.day {
  height: 15px!important;
  line-height: 15px!important;
  width: 15px!important;
}

.bootstrap-datetimepicker-widget table td.today {
  position: relative;
  text-shadow: none!important;
}

.timepicker-picker .table-condensed td {
  background-color: #ffffff!important;
}

.bootstrap-datetimepicker-widget table td.day:hover, .bootstrap-datetimepicker-widget table td.hour:hover, .bootstrap-datetimepicker-widget table td.minute:hover, .bootstrap-datetimepicker-widget table td.second:hover {
  background: #e9ecef!important;
}

.bootstrap-datetimepicker-widget table {
  border-bottom: 0px!important;
}

.fixed-table-container tbody td {
  border-left: 0px!important;
}

.bootstrap-table .table.table-no-bordered>tbody>tr>td, .bootstrap-table .table.table-no-bordered>thead>tr>th {
  border-right: 1px solid transparent!important;
  &:last-child {
    border-right: initial!important;
  }
}

.multiselect-container .input-group {
  width: auto!important;
}

.bootstrap-table .table.table-no-bordered>tbody>tr>td {
  border-right: 0px solid transparent!important;
}

.bootstrap-table .table.table-no-bordered>thead>tr>th {
  border-right: 1px solid transparent!important;
}

.fixed-table-pagination .pagination a {
  padding: 3px 12px!important;
  color: #7d7d7d;
  font-size: .9rem;
}

.bootstrap-table .fixed-table-container .fixed-table-body .fixed-table-loading {
  background: #fafbfe!important;
  font-size: .75rem!important;
}

span.loading-text {
  font-size: 1rem!important;
}

.error-row {
  background-color: #fff3f4;
}

.bg-success-muted {
  background-color: rgb(100 222 92 / 20%);
}

td.image {
  opacity: 0;
  animation: fadeIn .5s ease-in forwards;
}

.adv-search {
  line-height: 1.2;
  span {
    display: block;
  }
  &-details {
    font-size: 11px;
    margin: 4px 0 0;
    color: #777777;
    line-height: 1.3;
    
  }
}

.bootstrap-table .disabled .bs-checkbox {
  pointer-events: none;
  opacity: 0.2;
}

.bootstrap-table .disabled input[type="checkbox"] {
  pointer-events: none; /* Prevents interaction with the checkbox */
  opacity: 0.2; /* Visually indicates it's disabled */
}