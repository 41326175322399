/******************************
*
*	Component: Preview
*
******************************/

#layout_preview {
	position: relative;
	width: 300px;
	margin:0 auto;
	overflow: auto;
	clear: both;
	display: table;
	box-shadow: 0 0 20px #CCC;

	&.ipad {
		width:600px;
		zoom:0.6;
	}

	span.page_item {
		float:left;
		display: table-cell;
		position: relative;
		overflow:hidden;
		text-overflow:ellipsis;
		vertical-align: bottom;
			background-color: #EFEFEF;

		img {
			position: absolute;
			top: 0px;
			left:0px;
			width: 100%;
			border:0px;
		}

		&.type_1 {
			height:300px;
			width: 300px;
		}

		&.type_2 {
			height:150px;
			width: 300px;
		}

		&.type_3 {
			height:150px;
			width: 150px;
		}

		p {
			margin: 0px;
			color: #FFF;
			position: absolute;
			text-shadow: 0 0 10px #000;
			background-color: rgba(0,0,0,0.2);
			bottom:0px;
			padding: 5px;
			width: 100%;
			display: inline-block;

		}
	}
}


#carousel_preview {
	overflow: hidden;
	overflow-x: scroll;
	white-space: nowrap;
	padding: 0 0 10px 0;
	
	ul {
		margin: 0px;
		padding: 0px;
		width: auto;

		li {
      display: inline-block;
			height: 170px;
			width: 170px;
			border-radius: 6px;
			border: 1px solid #EFEFEF;
			list-style: none;
			margin-right: 5px;
			background-size: cover;
			position: relative;

			a.btn {
				position: absolute;
				opacity: 0.7;
				zoom: 70%;
			}

			span {
				background-color: rgba(0,0,0,0.8);
				color: #FFF;
				padding:2px 8px;
				font-weight: lighter;
				position: absolute;
				bottom: 0px;
				border-radius: 0 0 0 6px;
				font-size: 0.8em;

				&.link {
					right: 0px;
					border-radius: 0 0 6px 0;
          font-size: 60%;
          max-width: 90%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
				}
			}
		}

		.tile_advertisement {
			background-color: #ffffff;
			img {
				width: 100%;
				display: block;
			}
		}
	}
  
  //1.875:1
	&.wide {
		li {
      width: 260px;
    	height: 160px;
		}
  }
  
  //1:1
  &.fullwidth {
    li {
      width: 220px;
      height: 220px;
    }
  }

  //1:14
  &.extended {
    li {
      width: 236px;
      height: 315px;
    }
  }

}

.dragfile {

	.dropzone {
    outline: 4px dashed #FFF;
    outline-offset: -10px;
		text-align: center;
    padding: 40px;
    margin-left: 20px;

		.percentage {
      display:none;
    }

		span.fa, .percentage {
			font-size: 7em;
			padding-bottom: 10px;
		}

		.fa.small {
			font-size: 3em;
		}
		.percentage.small{
			font-size: 5em;
		}

	}

	label { 
		font-weight:bold;
		cursor: pointer;
	}

	&.is-dragover .dropzone {
	  background-color: #FFF;
	}

	input {display:none;}

	&.is-dropped {
		.percentage {display: inline-block;}
		.instructions {display:none;}
	}
}

#CarouselPreview {
  border: 1px solid #CCC;
  background: #ebebeb;
	overflow: hidden;
	position: relative;
  border-radius: 3px;

  //13:9
	&.wide {
    width: 350px;
    height: 206px;
  }
  
  //1:1
  &.fullwidth {
    width: 350px;
    height: 350px;
  }

	input {
		position: absolute;
		bottom: 5px;
		background-color: rgba(0,0,0,0.4);
		border:none;
		outline: none;
		color: #FFF;
		left:5px;
		right:5px;
		width: 367px;

		&#cp_Title {
			bottom:45px;
		}
	}

	#cp_Image {
		width: 100%;
	}
}