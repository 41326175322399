/******************************
*
*	Component: Tabs
*
******************************/

ul.nav-tabs {
  white-space: nowrap;
  border-bottom: 1px solid $heading_bar !important;
  margin: 15px 0 0 5px!important;
  >li {
    float: none;
    display: inline-block;
    >a {
      font: {
        size: .8em;
        weight: 600;
      }
      border: none !important;
      color: $text;
      background-color: transparent !important; 
      margin: 10px 0px 0px 14px;
      padding: 0;
      white-space: nowrap;
      text-transform: uppercase;
      &:hover {
        color: $text_hover;
      }
  
      &.active {
        border-bottom: 3px solid $brand-primary !important;
        color: $brand-primary !important;
      }
    } 
  }
} 

.tab-pane {
  padding: 20px;

  >h3 {
    margin-top: 0;
    margin-bottom: 10px;
  }
}

.tab-content.content .tab-pane {
  padding: 0px;
}

.nav-fill {
  border-radius: 6px; 
  flex-wrap: nowrap;

  .nav-item {
    border-radius: 0px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    border-right: 1px solid;
    outline: none;
  }

  .nav-item:last-child {
    border-right: none;
  }

  .nav-item.active:first-child {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  .nav-item.active:last-child {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }

}