/******************************
*
*	Component: Language Options
*
******************************/

.language-option {
	position: relative;
	display: none;

	.badge {
		position: absolute;
		top:10px;
		right:10px;
		z-index: 3;
	}
	.form-control {
		margin-bottom: 10px;
	}

	&.show, &.default {
		display: block;
	}
}


pre {
	font-size: 75%;
	white-space: pre-wrap;
}


button.loading:before {
	content:"\f110";
}

button.playing:before {
	content:"\f04d";
}

button.stop:before {
	content:"\f04d";
}
