@font-face {
  font-family: 'tunedicons';
  src: url('../../fonts/tunedicons.eot');
  src: url('../../fonts/tunedicons.ttf') format('truetype'),
    url('../../fonts/tunedicons.woff') format('woff'),
    url('../../fonts/tunedicons.svg#tunedicons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}


.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'tunedicons' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-nav_airplay:before {
  content: "\2c6";
}
.icon-close-round:before {
  content: "\2211";
}
.icon-downloading:before {
  content: "\2013";
}
.icon-info:before {
  content: "\f7";
}
.icon-info-error:before {
  content: "\f7";
  color: $alert-danger;
  font-size: 20px;
}
.icon-follow:before {
  content: "\7d";
}
.icon-icon-lyrics:before {
  content: "\3a";
}
.icon-author:before {
  content: "\5f";
}
.icon-video-round:before {
  content: "\46";
}
.icon-note-round:before {
  content: "\53";
}
.icon-nav_search_round:before {
  content: "\57";
}
.icon-queue-play-next:before {
  content: "\24";
}
.icon-eye-off:before {
  content: "\7e";
}
.icon-volume-mute:before {
  content: "\7c";
}
.icon-shuffle-active:before {
  content: "\3f";
}
.icon-loop-single-active:before {
  content: "\142";
}
.icon-loop-active:before {
  content: "\2a";
}
.icon-arrow-prefix:before {
  content: "\56";
  color: #555;
}
.icon-unticked:before {
  content: "\43";
}
.icon-ticked:before {
  content: "\44";
}
.icon-ticked-success:before {
  content: "\44";
  color: $alert-success;
  font-size: 18px;
}
.icon-filter:before {
  content: "\22";
}
.icon-sort:before {
  content: "\27";
}
.icon-icon-addmusic:before {
  content: "\29";
}
.icon-icon-musicnote:before {
  content: "\30";
}
.icon-icon-replayvideo:before {
  content: "\32";
}
.icon-icon-video-outlined:before {
  content: "\31";
}
.icon-icon-video:before {
  content: "\74";
}
.icon-queue:before {
  content: "\2022";
}
.icon-music-album:before {
  content: "\a2";
}
.icon-app_playlist:before {
  content: "\60";
}
.icon-user_playlist:before {
  content: "\a1";
}
.icon-home:before {
  content: "\2260";
}
.icon-add_track_toggle_active:before {
  content: "\2265";
}
.icon-add_track_toggle:before {
  content: "\2264";
}
.icon-add_playlist:before {
  content: "\2202";
}
.icon-add_song:before {
  content: "\221e";
}
.icon-more_vertical:before {
  content: "\201c";
}
.icon-female:before {
  content: "\2640";
}
.icon-email:before {
  content: "\eb";
}
.icon-addtoqueue:before {
  content: "\61";
}
.icon-artist:before {
  content: "\e5";
}
.icon-clock:before {
  content: "\40";
}
.icon-calendar:before {
  content: "\107";
}
.icon-star_filled:before {
  content: "\263b";
}
.icon-star_empty:before {
  content: "\263a";
}
.icon-download_ok:before {
  content: "\221a";
}
.icon-download:before {
  content: "\2193";
}
.icon-download_error:before {
  content: "\64";
}
.icon-megaphone:before {
  content: "\28";
}
.icon-camera:before {
  content: "\3b";
}
.icon-pictures:before {
  content: "\51";
}
.icon-trash:before {
  content: "\59";
}
.icon-redo:before {
  content: "\4a";
}
.icon-cog:before {
  content: "\263c";
}
.icon-location:before {
  content: "\25bc";
}
.icon-profile:before {
  content: "\4f";
}
.icon-user-male:before {
  content: "\2642";
}
.icon-key:before {
  content: "\4b";
}
.icon-padlock:before {
  content: "\50";
}
.icon-unlock:before {
  content: "\4c";
}
.icon-share:before {
  content: "\7b";
}
.icon-warning:before {
  content: "\69";
}
.icon-loop:before {
  content: "\6c";
}
.icon-shuffle:before {
  content: "\34";
}
.icon-song:before {
  content: "\79";
}
.icon-volume:before {
  content: "\2d";
}
.icon-album:before {
  content: "\6f";
}
.icon-playlist:before {
  content: "\70";
}
.icon-radio:before {
  content: "\72";
}
.icon-player-settings:before {
  content: "\25";
}
.icon-up:before {
  content: "\2e";
}
.icon-down:before {
  content: "\2c";
}
.icon-right:before {
  content: "\5d";
}
.icon-left:before {
  content: "\5b";
}
.icon-cross:before {
  content: "\58";
}
.icon-more:before {
  content: "\2026";
}
.icon-plus:before {
  content: "\2b";
}
.icon-search:before {
  content: "\26";
}
.icon-tick:before {
  content: "\54";
}
.icon-drag-vertical:before {
  content: "\2195";
}
.icon-grid-large:before {
  content: "\47";
}
.icon-grid-small:before {
  content: "\67";
}
.icon-list:before {
  content: "\23";
}
.icon-thumb-down:before {
  content: "\16b";
}
.icon-thumb-up:before {
  content: "\14d";
}
.icon-voucher:before {
  content: "\f6";
}
.icon-eye:before {
  content: "\49";
}
.icon-microphone:before {
  content: "\21";
}
.icon-chromecast:before {
  content: "\63";
}
.icon-explicit:before {
  content: "\65";
}
.icon-followers:before {
  content: "\66";
}
.icon-next:before {
  content: "\3e";
}
.icon-pause:before {
  content: "\3d";
}
.icon-pencil:before {
  content: "\2f";
}
.icon-play:before {
  content: "\5e";
}
.icon-previous:before {
  content: "\3c";
}
.icon-karaoke:before {
  content: "\2b08";
}
.icon-audiobooks:before {
  content: "\22c2";
}