/******************************
*
*	Mixins
*
******************************/

@mixin breakpoint($point) {
  @if $point==desktop {
    @media (min-width: 70em) {
      @content;
    }
  }

  @else if $point==laptop {
    @media (min-width: 64em) {
      @content;
    }
  }

  @else if $point==tablet {
    @media (min-width: 50em) {
      @content;
    }
  }

  @else if $point==phablet {
    @media (min-width: 37.5em) {
      @content;
    }
  }

  @else if $point==mobileonly {
    @media (max-width: 37.5em) {
      @content;
    }
  }
}

@mixin animation($animation-val...) {
  -webkit-animation: $animation-val;
  -moz-animation: $animation-val;
  -ms-animation: $animation-val;
}

@mixin animation-delay($val...) {
  -webkit-animation-delay: $val;
  -moz-animation-delay: $val;
  -ms-animation-delay: $val;
}

@mixin border-radius($radius: 3px) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
  background-clip: padding-box;
}

@mixin transform($transforms) {
  -moz-transform: $transforms;
  -ms-transform: $transforms;
  -webkit-transform: $transforms;
  transform: $transforms;
}

@mixin rotate ($deg) {
  @include transform(rotate(#{$deg}deg));
}

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content; 
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  } 
}