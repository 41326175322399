#page_header {
  position: fixed;
  top: 0;
  background: rgb(240,90,36);
  background: -moz-linear-gradient(140deg, rgba(240,90,36,1) 0%, rgba(238,84,65,1) 70%);
  background: -webkit-linear-gradient(140deg, rgba(240,90,36,1) 0%, rgba(238,84,65,1) 70%);
  background: linear-gradient(140deg, rgba(240,90,36,1) 0%, rgba(238,84,65,1) 70%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f05a24",endColorstr="#ee5441",GradientType=1);
  height: $header_height;
  width: 100%;
  z-index: 20;
  transition: all 0.1s ease;
  box-shadow: 0px 0px 35px 0px rgb(154 161 171 / 35%);
  display: flex;
  justify-content: space-between;
  align-items: center;

  .logo-container {
    height: $header_height;
    padding: 0 20px 0 20px;
    max-width: 220px;
    border-right: 1px solid rgba(250, 250, 250, 0.2);
    font-size: 1.5rem;
    font-weight: bold;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-start;
  }
  
  .logo {
    background-image: url('/images/logo.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 150px;
    height: 28px;
    position: relative;
  }

  .spinner {
    position: absolute;
    top: 7px;
    left: 10px;
    width: 30px;
    height: 30px;
    display: inline-block;
    border-radius: 50%;
    background-color: rgba(250, 250, 250, 0.4);
    font-size: 19px;
    transition: background-color 0.3s ease;
    text-align: center;
    line-height: 31px;
    color: #ffffff;
  }

  .store {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    gap: 10px;
  }

  .storename {
    margin: 0 0 0 20px;
    max-width: 250px;
    font-weight: 600;
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    letter-spacing: 0px;
    color: #ffffff;
    font-size: 19px;
  }

  .package {
    position: relative;
    left: 0;
  }

  .header-nav {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    margin: 0 10px 0 0;
    &-button {
      align-items: center;
      justify-content: flex-start;
      color: #ffffff;
      font-weight: 500;
      z-index: 1;
      position: relative;
      cursor: pointer;
      &-container {
        color: #ffffff;
        font-weight: 600;
      }
      &-inner {
        padding: 3px 28px 3px 10px;
        border-radius: 5px;
        line-height: 1.3;
        height: 36px;
        display: flex;
        align-items: center;
        transition: background-color .3s ease-in-out, color .1s ease-in-out;
        &:hover {
          background-color: rgba(0, 0, 0, 0.2);
        }
      }
      .fa {
        font-size: 20px;
        color: #ffffff;
        margin-top: 1px;
      }
      a {
        padding: 7px;
        width: auto!important;
        height: initial!important;
        border-radius: 5px;
        transition: background-color .3s ease-in-out, color .1s ease-in-out;
        &:hover {
          background-color: rgba(0, 0, 0, 0.2);
        }
      }
      .chevron {
        color: #ffffff;
        position: absolute;
        right: 10px;
        top: 47%;
        font-size: 12px;
        transform: translateY(-50%);
      }
    } 
  }

  .client-package {
    border-radius: 100%;
    width: 15px;
    height: 15px;
    display: inline-block;
    &.snap {
      background-image: url('/images/badge-silver.png');
      background-repeat: no-repeat;
      background-size: cover;
    }
    &.stretch {
      background-image: url('/images/badge-gold.png');
      background-repeat: no-repeat;
      background-size: cover;
    }
    &.extreme {
      background-image: url('/images/badge-platinum.png');
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  .profile {
    &-details {
      font-size: 0.85em;
      flex-direction: column;
      align-items: flex-start;
      small {
        font-size: 10px;
        display: block;
        color: rgba(250, 250, 250, 0.7);
      }
    }
  }

  .environment {
    text-transform: capitalize;
  }

  button.btn-link {
    color: #FFF;
  }
}