/******************************
*
*	Components: Forms
*
******************************/

.input-group-addon.small{
	font-size: 80%;
	font-weight: lighter;
}

.form-control.error {
	border-color: red;
}

textarea.form-control {
	padding-right: 40px;
}

textarea, pre {
    -moz-tab-size : 2;
      -o-tab-size : 2;
         tab-size : 2;
}

.form-control {
  border-radius: 1.5rem;
  font-size: .95rem;
  padding: 0.375rem 1rem;
}

label {
  margin-bottom: 0.3rem;
}

label.error {
	color: red;
	font-weight: 400;
  font-size: 12px;
}

label small{
	font-weight: normal;
	opacity: 0.8;
}

.multiselect-container {
  width: 100%;
  .checkbox {
    padding: 4px 15px;
    width: 100%;
  }
  a {
    color: #5a5a5a!important;
  }
}

.multiselect-container>li {
  width: 50%!important; 
  float: left!important;
}

.multiselect-container>li:first-child {
  width: 100%!important;
  float: none!important;
}

.form-control-select {
  max-height: 100%;
  width: 100%;
  display: block;
  padding: 0.375rem 1rem;
  font-size: .95rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 1.5rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  text-align: left;
}

.disabled {
  pointer-events: none;
  outline: none;
  background-color: #e9ecef;
  opacity: 1;
}

.radio-btn {
  padding: 0 20px 0 0;
}

.search input.form-control {
  padding-left: 35px; 
}

.search {
  &:after {
    content: "\f002";
    position: absolute;
    top: 30%;
    left: 12px;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    color: #495057!important;
  }
}

.search-input {
  position: relative;
  &:after {
    content: "\f002";
    position: absolute;
    top: 30%;
    left: 12px;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    color: #495057!important;
  }
  .form-control {
    padding-left: 35px;
    border-radius: 1.5rem;
  }
}

.artist-input {
  position: relative;
  &:after {
    @extend .icon;
    content: "\e5";
    position: absolute;
    top: 25%;
    left: 12px;
    font-size: inherit;
    color: #495057!important;
  }
  .form-control {
    padding-left: 35px;
  }
}

.date-input {
  position: relative;
  &:after {
    content: "\f133";
    position: absolute;
    top: 30%;
    left: 12px;
    z-index: 3;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    color: #495057!important;
  }
  .form-control {
    padding-left: 35px;
  }
}

.no-field {
  border: 0;
  background: transparent!important;
  padding: 2px;
  font-size: 14px;
  &:focus {
    outline: none!important;
    box-shadow: 0 0 0 0;
  }
}

.form-check-input {
  margin-top: 0.25rem!important;
}

.ui-slider-horizontal {
  height: 6px;
}

.ui-slider-horizontal .ui-slider-range {
  background: #666666;
}

.ui-slider-handle {
  top: -7px!important;
  border-radius: 100%;
}

.error-message {
  color: red;
  font-size: 14px;
  margin-top: 10px;
}

.pwd-restrictions {
  list-style: none;
  padding: 0;
}
.pwd-restrictions li {
  padding: 0 0 0 20px;
  margin: 0 0 0 5px;
  line-height: 2.0em;
}
.pwd-restriction-checked {
  position: relative;
  &:before {
    position: absolute;
    font-family: "FontAwesome";
    content: '\f00c';
    left: 0;
    color: $alert-success;
  }
  .pwd-cross {
    display: none;
  }
}

.pwd-cross {
  margin: 0 10px 0 -20px;
  color: $alert-danger;
}

.multiselect-filter{
  label {
    color: #495057!important;
  }
}

.underline-link {
  border-bottom: 1px solid;
}

.instructions {
  padding: 20px 0 0;
}

.loader {
  position: relative;
  width: 100%;
  height: 50px;
  .container {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: -0.5px;
    width: 200px;
    height: 100px;
    .spinner {
      div {
        border: 6px solid #444444!important;
        border-color: #444444 transparent transparent transparent!important;
      }
    }
    .loading-text {
      text-align: center;
      text-transform: initial;
      margin: 0 0 0;
      padding: 0 0 50px 0;
    }
  }
}

.priority-item {
  height: 20px;
  padding: 6px 3px 10px 3px;
  display: inline-block;
}

select#prioritySelect {
  border: 0;
  font-size: 9px;
  padding: 0; 
  font-weight: bold;
  height: 20px;
  border-radius: 3px;
  text-align: center;
  outline: none;
} 

.tag-score-select { 
  font-size: 12px;
  padding: 0;
  font-weight: bold;
  height: 30px;
  border-radius: 3px;
  text-align: center;
  outline: none;
  min-width: 80px;
} 

input {
  &.form-control {
    &.multiselect-search {
      border-top-left-radius: 30px !important;
      border-bottom-left-radius: 30px !important;
      border-top-right-radius: 30px !important;
      border-bottom-right-radius: 30px !important;
    }
  }
}

.spinner {
  display: inline-block;
  position: relative;
  width: 50px;
  height: 50px;
  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 40px;
    height: 40px;
    margin: 6px;
    border: 6px solid #5d5d5d;
    @include border-radius(50%);
    @include animation(spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite);
    border-color: #5d5d5d transparent transparent transparent;
    &:nth-child(1) {
      @include animation-delay(-0.45s);
    }
    &:nth-child(2) {
      @include animation-delay(-0.3s);
    }
    &:nth-child(3) {
      @include animation-delay(-0.15s);
    }
  }
}

@include keyframes(spinner) {
  0% {
    @include transform(rotate(0deg));
  }
  100% {
    @include transform(rotate(360deg));
  }
}
