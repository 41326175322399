/******************************
*
*	Layout
*
******************************/

html, body {
  overflow: auto;
}

body {
  background-color: #FFF;
  color: $text;
  font-weight: 400;
  height: 100%;
  font-size: .95rem;
  &.notips .tips {
    display: none;
  }
  .tips {
    position: relative;
    &:before {
      position: absolute;
      font-family: "FontAwesome";
      content: '\f05a';
    }
    p {
      margin: 0 0 0 20px;
    }
    p:last-child {
      margin-bottom: 0;
    }
  }
}

a {
  color: $text;
  cursor: pointer;
  font-weight: 400;
  &:hover {
    color: $text;
  }
}

hr {
  margin: 20px 0 0 0;
}

label {
  font-weight: 500;
}

#framework {
  position: relative;
}

.header-menu {
  -webkit-box-shadow: 0 2px 4px rgba(50, 50, 50, .2);
  box-shadow: 0 2px 4px rgba(50, 50, 50, .2);
  width: 230px;
  max-height: 356px;
  overflow-y: scroll;
  border-radius: 5px;
  margin: 0;
  position: absolute;
  top: calc($header_height - 4px);
  right: 0;
  .menu-row {
    background-color: #ffffff;
    color: #ffffff;
    margin-top: -8px;
    padding: 10px 0 2px;
    border-radius: 3px;
    position: relative;
  }
  .nav-pills {
    background-color: #ffffff;
  }
  .groups {
    background: #f4f4f4;
    color: #333333;
    padding: 5px 0;
    position: relative;
    .nav-link {
      padding: 2px 10px!important;
    }
    p {
      padding: 10px;
    }
  }
  hr {
    padding: 0;
    margin: 0;
  }
  .nav-link {
    color: #5a5a5a!important;
    padding: 8px 5px!important;
    font-weight: 600;
    transition: background-color .3s ease-in-out,color .1s ease-in-out;
    font-size: 14px;
    border-radius: 3px;
    margin: 6px;
    .chevron {
      font-size: 12px;
      margin: 3px 0 0;
    }
    &:hover {
      color: #f05a24!important;
      background: #f4f4f4!important;
    }
    .menu-icon {
      text-align: center;
      width: 19px;
      margin-right: 5px;
    }
    span {
      &:before {
        color: #5a5a5a;
      }
    }
  }
}

#status {
  position: fixed;
  bottom: 0px;
  width: $menu_width;
  margin-bottom: 0px;
  font-size: 60%;
  border-radius: 0;
  background-color: #f5f5f5;
  border: 0;
  color: #888888;
}

.changelog li {
  margin-bottom: 10px;
}

#page_section {
  background-color: #fafbfe;
  position: relative;
  top: $header_height;
  bottom: 0;
  height: auto!important;
  padding-bottom: 100px;
  visibility: hidden;
  .section-content {
    display: inline-block;
    width: 100%;
    height: 100vh;
  }
}

.fullwidth {
  width: 100%!important;
}

#gutter {
  display: none;
  position: fixed;
  left: 50%;
  background-color: transparent;
  background-image: url('/images/gutter.png');
  background-repeat: no-repeat;
  background-position: 50%;
  height: 100%;
  width: 10px;
  z-index: 100;
  cursor: ew-resize;
  top: $header_height;
  &:hover {
    background-color: rgb(118, 126, 130);
  }
}

#page_item {
  display: none;
  background-color: #ebeef2;
  overflow: hidden;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 10px;
  text-align: center;
  z-index: 3;
  overflow-y: auto;
  &.show {
    display: inline-block !important;
  }
}

.item {
  &-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
  &-content {
    height: 100%;
    overflow-y: auto;
    background-color: #FFF;
    display: inline-block;
    text-align: left;
    width: 95%;
    margin-top: 60px;
    position: relative;
    border-radius:3px;
  }
}

button {
  &.item-close,
  &.item-reload {
    z-index: 9000;
    position: fixed;
    right: 3px;
    top: 53px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 3px solid #e2e2e2;
    color: #6a6a6a;
    background-color: #FFF;
    padding-left: 7px;

    &:hover {
      background-color: #EFEFEF;
    }
    &.item-reload {
      display: none;
    }
  }
}

.btn {
  border-radius: 1.5rem;
  &-primary {
    background: $brand-primary;
    border: $brand-primary; 
  }
  &-primary:hover {
    background: $brand-primary;
    border: $brand-primary; 
  }
  &.fa {
    padding: 8px;
    min-width: 33px;
  }
  &.btn-primary {
    color: #FFF !important;
  }
  &.btn-outline {
    border: 1px solid #b2b2b2;
    border-radius: 1.5rem;
    background: #fff;
    padding: 5px 10px;
    color: #5a5a5a;
  }
  &.btn-login {
    background: $brand-primary;
    border-color: $brand-primary;
    &:hover, &:active, &:focus {
      background: lighten($brand-primary, 10%);
      border-color: lighten($brand-primary, 10%);
    }
  }
  &.btn-showkey {
    outline: none;
    border: none;
    color: #5a5a5a;
    cursor: pointer;
  }
}

a.btn.btn-outline {
  border: 1px solid #b2b2b2;
  border-radius: 3px;
  background: #ffffff;
  color: #5a5a5a;
}

.input-group-addon {
  .btn-link {
    padding: 0px;
  }
}

.panel-heading {
  .float-right button {
    margin-top: -8px;
  }
}

.editor-container {
  margin: 12px 0 15px 0;
  padding: 8px;
  min-height: 100vh;
}

.heading-container {
  display: inline-block;
  width: 100%;
  h2 {
    font-weight: 500;
  }
}
 
.health-check-list {
  list-style: none;
  margin: 0;
  padding: 5px;
  li {
    padding: 3px 0;
  }
}

#tab_mnd_search .fixed-table-pagination {
  display: none!important;
}

.fixed-table-pagination {
  .btn-secondary {
    font-size: .7rem;
    padding: 1px 8px;
  }
}

.lead {
  font-size: 1.1rem;
}

.dragfile .dropzone {
  .lead {
    font-size: .9rem;
  }
}

.advanced-search {
  &-details {
    text-align: center;
    font-size: .9rem;
    line-height: 1.1;
  }
  &-tags {
    line-height: 1;
  }
}

.preview {
  position: relative;
}

.overlay {
  &:before {
    content: '';
    position: fixed;
    background-color: rgba(0,0,0,0.3);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 15;
  }
}

.ml-3, .mx-3 {
  margin-left: .5rem!important;
}

.border-bottom {
  border-bottom: 1px solid rgb(235, 235, 235)!important;
  padding: 6px 0;
  &:nth-last-child{
    border-bottom: 0px!important;
  }
}

.nomargin {
  margin: 0;
}

.login {
  &-banner {
    background: rgb(240,90,36);
    background: -moz-linear-gradient(140deg, rgba(240,90,36,1) 0%, rgba(238,84,65,1) 70%);
    background: -webkit-linear-gradient(140deg, rgba(240,90,36,1) 0%, rgba(238,84,65,1) 70%);
    background: linear-gradient(140deg, rgba(240,90,36,1) 0%, rgba(238,84,65,1) 70%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f05a24",endColorstr="#ee5441",GradientType=1);
    min-height: 150px;
    position: relative;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  &-logo {
    width: 158px;
    height: 62px;
    background-image: url('/images/login-logo.png');
    background-repeat: no-repeat;
    background-size: contain;
    margin: 0;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

#playlist_to_csv { 
  visibility: collapse;
} 

.forgot-password {
  color: #666666;
}

.vertical-alignment-helper {
  display:table;
  height: 100%;
  width: 100%;
  pointer-events: none;
}

.vertical-align-center {
  display: table-cell;
  vertical-align: middle;
  pointer-events: none;
}

.modal-content {
  width: inherit;
  max-width: inherit; 
  height: inherit;
  margin: 0 auto;
  pointer-events: all;
}

.csv .pagination-detail {
  display: none;
}

.close {
  font-weight: initial!important;
  text-shadow: none!important;
  font-size: 1.35rem!important;
}

body > .alert {
  padding: 10px 15px!important;
  .text-center {
    text-align: center;
  }
}

.two-factor-form {
  display: flex;
  justify-content: center;
  input {
    margin: 0 0.5rem;
    padding: 0.5rem;
    border: 0.1rem solid #dddddd;
    width: 50px;
    height: 50px;
    text-align: center;
    border-radius: 5px;
    font-size: 2.5rem;
    line-height: normal;
  }
}

a.nav-item.nav-link {
  padding: 6px;
  font-size: .8rem;
}

.card-header {
  padding: 0.45rem 1rem;
  line-height: 30px;
  font-weight: 500;
}

.nav-pills .nav-link.active {
  background-color: #2d2d2d;
}

nav.nav.nav-pills.nav-fill.border.border-primary {
  border-color: #939393!important;
}

.fixed-table-container tbody td .th-inner, .fixed-table-container thead th .th-inner {
  padding: 8px 8px 8px 8px!important;
}

.vertical-center {
  display: flex;
  align-items: center;
}

#loading {
  height: 100vh;
  width: 100vw;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: $brand-primary;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.inherit-width {
  width: inherit;
}

.small, small {
  font-size: 85%;
}

#bulkactions {
  .dropdown-menu {
    min-width: 14rem!important;
    a.nav-link {
      font-size: 14px;
      color: #52575b;
      font-weight: 400;
      padding: 4px 15px;
    }
  }
}

.page-loading {
  top: 50%;
  position: relative;
  filter: brightness(220%);
}

.row.display-flex {
  display: flex;
  flex-wrap: wrap;
}

.row.display-flex > [class*='col-'] {
  display: flex;
  flex-direction: column;
}

.tooltip .tooltip-inner {
  text-align: left;
}
