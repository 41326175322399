/******************************
*
*	Helpers
*
******************************/

div.inline {
  display: inline;
}

nowrap {
  white-space: nowrap;
}

.vert-middle {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.padded {
  padding: 20px;
}

.dark_overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  opacity: 0.2;
}

.verified_tick {
  background-color: #4CAF50;
  border-radius: 50%;
  color: #FFF;
  font-size: 60%;
  padding: 4px 4.5px;
  font-weight: bold;
  position: absolute;
  top: 13px;
  left: 57px;
  height: 17px;
  width: 17px;
}

.spinthrob {
  animation: spinthrob 0.5s infinite linear;
}

@keyframes spinthrob {
  0% {
    transform: scale(1) rotate(0deg);
  }

  50% {
    transform: scale(1.5) rotate(45deg);
  }

  100% {
    transform: scale(1) rotate(89.5deg);
  }
}

.spin {
  animation: spin 2s infinite linear;
  background-color: transparent !important;
  ;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}

.nowrap {
  white-space: nowrap;
}

.wrap-all {
  word-break: break-all;
}

.table-fixed-wide {
  table-layout: fixed;
  width: 100%;
}

.cell-nowrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.row.no-pad {
  margin-right: 0;
  margin-left: 0;
}

.row.no-pad>[class*='col-'] {
  padding-right: 0;
  padding-left: 0;
}

.code {
  font: {
    family: Consolas, Lucida Console, monospace;
    size: 80%;
  }
}

.hide {
  display: none;
  visibility: hidden;
}

b, strong,
.strong {
  font-weight: 500;
}

.vertically-align {
  align-items: center;
  justify-content: center;
}