/******************************
*
*	Main
*
******************************/

@import "variables.scss";
@import "mixins.scss";
@import "helpers.scss";

@import "header.scss";
@import "menu.scss";
@import "layout.scss";

@import "breakpoints.scss";

@import "components/modals.scss";
@import "components/alerts.scss";
@import "components/headings.scss";
@import "components/buttons.scss";
@import "components/forms.scss";
@import "components/tables.scss";
@import "components/tabs.scss";
@import "components/language.scss";
@import "components/search.scss";
@import "components/editing.scss";
@import "components/content.scss";
@import "components/preview.scss";
@import "components/stats.scss";
@import "components/lyriciq.scss";
@import "components/pagination.scss";
@import "components/badges.scss";
@import "components/icons.scss";


// Open AI
#chat-container {
  margin: 0;
}

.message {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.user {
  background-color: rgb(243, 243, 243);
  text-align: left;
  font-weight: 500;
}

.bot {
  border: 1px solid #e7e7e7;
  text-align: left;
  min-height: 190px;
}

.response-table {
  margin-bottom: auto!important;
  width: 100%;
  td, th {
    padding: initial!important;
  }
  .table-image {
    margin: 2px;
  }
}

.checkbox-cell {
  text-align: center;
}

.select-all-checkbox {
  margin: 0 10px;
}