/******************************
*
*	Components: Headings
*
******************************/

h2 {
	font-weight: 400;
	font-size: 1.6rem;
	padding-left: 20px;
	margin: 20px 20px 0 0;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	position: relative;
	line-height: 1.2;
}

h3 {
	font-size: 1.3rem!important;
	font-weight: 400!important;
}

.profile_header {
	display: inline-block;
	width: 100%;

	img {
		margin: 12px 12px 0 12px;
		float: left;
		height: 60px;
		opacity: 0; 
		animation: fadeIn 0.5s ease-in forwards;
	}

	p.item-type {
		position: inherit;
		border-left: none;
		margin: 10px 0 0;
    font-weight: 400;
	}

	h2 {
		border-left: none;
		margin: 0px;
		padding-left: 0px;
		position: relative;
		.title {
			padding-right: 10px;
			max-width: 70%;
			display: inline-block;
			font-size: 1.5rem;
			overflow: hidden;
			text-overflow: ellipsis;
			position: relative;
			line-height: 1.2;
		}
		.priority {
			position: absolute;
			display: inline-block;
			top: 4px;
		}
	}
} 

p.item-type {
	padding-left: 20px;
	padding-top: 5px;
	margin: 20px 20px 0 0;
	position: absolute;
	white-space: nowrap;
	font-size: 12px;
	top: -17px;
  color: $brand-primary;
  font-weight: 400;
}

.item-content h3 {
	margin: 20px 20px 0 -10px;
	padding-left: 10px;
	font-weight: 100;
}

.heading-buttons{
	margin: 20px 20px 0 0;
	button {
		border: 1px solid #6c757d;
    background: 000000;
    color: #6c757d;
	}
}