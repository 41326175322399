/******************************
*
*	Component: LyricIQ
*
******************************/

.sentiment-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
  cursor: pointer;
}

.sentiment-badge {
  border-radius: 10px;
  margin-right: 5px;
  text-align: center;
  padding: 3px 10px;
  color: #ffffff;
  font: {
    size: 12px;
    weight: 500;
  }
}

.red {
  background-color: #d72f2f;
}

.light-red {
  background-color: #ff6666; 
}

.yellow {
  background-color: #eba61b;
}

.grey {
  background-color: #a9a9a9;
}

.cyan {
  background-color: #28861f; 
}

.green {
  background-color: #26b526;
}

.light-green {
  background-color: #50dc50;  
}

.sentiment-dot:hover::after {
  position: absolute;
  top: -25px;
  left: 0;
  padding: 5px;
  background-color: black;
  color: white;
  border-radius: 5px;
  white-space: nowrap;
}

.sentiment-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3px;
  background-color: #bcbcbc; 
  position: relative;
  margin: 30px 40px 60px 0;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #d1d1d1; 
  border: 1px solid #ffffff;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  &.active {
    width: 16px;
    height: 16px;
  }
  &-label {
    position: absolute;
    line-height: 12px;
    top: 18px;
    left: 0px;
    font: {
      weight: 400;
      size: 10px;
    }
    color: #969696;
  }
  .label-active {
    color: #636363;
    font: {
      weight: 500;
      size: 12px;
    }
  }
}

[data-sentiment="extremely-negative"] {
  right: 0%;
  left: auto;
  background-color: #d72f2f;
}

[data-sentiment="negative"] {
  right: 16.67%;
  left: auto;
  background-color: #ff6666;
}

[data-sentiment="somewhat-negative"] {
  right: 33.33%;
  left: auto;
  background-color: #eba61b;
}

[data-sentiment="neutral"] {
  right: 50%;
  left: auto;
  background-color: #444444;
}

[data-sentiment="somewhat-positive"] {
  right: 66.67%;
  left: auto;
  background-color: #28861f;
}

[data-sentiment="positive"] {
  right: 83.33%;
  left: auto;
  background-color: #26b526;
}

[data-sentiment="extremely-positive"] {
  left: 0%;
  background-color: #50dc50;
}

.content-filter-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 20px;
  flex-wrap: wrap;
  margin: 0 0 20px 0;
  overflow: hidden;
}

.content-filter {
  flex: 1 0 20%;
  text-align: center;
  background-color: #e7e7e7;
  padding: 2px;
  border: 1px solid #ffffff;
  color: #969696;
  font: { 
    size: 10px;
    weight: 500;
  }
}

.content-filter.highlight {
  background-color: #d72f2f;
  color: #ffffff
}

.content-filter:first-child::before,
.content-filter:last-child::after {
  content: '';
  display: block;
  width: 10px;
  height: 100%;
  background-color: #f0f0f0;
  border-radius: 50%;
}

.content-filter:first-child::before {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px; 
}

.content-filter:last-child::after {
  border-top-right-radius: 20px; 
  border-bottom-right-radius: 20px;
}

.emotion-name {
  margin: 10px 0 0 0;
  font: {
    weight: 500;
    size: 12px;
  }
}

.emotion-bar {
  height: 20px;
  border-radius: 5px;
  margin-top: 1px;
  position: relative;
  overflow: hidden;
  span.emotion-percentage {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #333333;
    font-size: 12px; 
    font-weight: 600;
  }
}

.emotion-filter-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 20px;
  flex-wrap: wrap;
  overflow: hidden;
  margin: 0 0 10px;
}

.emotion-filter {
  flex: 1 0 20%;
  text-align: center;
  background-color: #e7e7e7;
  padding: 2px;
  border: 1px solid #ffffff;
  color: #969696;
  font: { 
    size: 10px;
    weight: 500;
  }
}

.emotion-filter.highlight {
  background-color: #eba61b;
  color: #ffffff
}

.emotion-filter:first-child::before,
.emotion-filter:last-child::after {
  content: '';
  display: block;
  width: 10px;
  height: 100%;
  background-color: #f0f0f0;
  border-radius: 50%;
}

.emotion-filter:first-child::before {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px; 
}

.emotion-filter:last-child::after {
  border-top-right-radius: 20px; 
  border-bottom-right-radius: 20px;
}

.content-name {
  font: {
    weight: 500;
    size: 12px;
  }
}