/******************************
*
*	Components: Pagination
*
******************************/

.pagination {
  &-detail {
    width: 100%;
    color: #909090;
    font: {
      size: .7rem;
      weight: 400;
    }
  }
  .page-item.active .page-link {
    background: #6c757d;
    border-color: #6c757d;
  }
}