/******************************
*
*	Component: Page Editing
*
******************************/

.display-type-row {
	border-bottom: 3px solid red;
}

td.nopadding {
	position: relative;
}


tr.selected.bg-success{
	background-color: #dff0d8!important;
	td {
		background-color: transparent;
	}
}

tr.selected>td.bg-success{
	background-color: #dff0d8!important;
}

td img.table-image {
	max-width: 60px;
	width: 60px;
	height: 60px;
	border-radius: 4px;
	opacity: 0; 
	animation: fadeIn 0.5s ease-in forwards;
}

td img.table-image-video {
	max-width: 100px;
	border-radius: 4px;
	opacity: 0; 
	animation: fadeIn 0.5s ease-in forwards;
}

td img.table-tile-image {
	max-width: 130px;
	border-radius: 2px;
	opacity: 0; 
	animation: fadeIn 0.5s ease-in forwards;
}

td.small img.table-image {
	max-width: 30px;
	opacity: 0; 
	animation: fadeIn 0.5s ease-in forwards;
}

td.nopadding img {
	margin: -8px;
}


div.nopadding {
	padding:0px;
}

.table-image {
	position: relative;
	max-width: 40px;
	max-width: fit-content; 
	opacity: 0; 
	animation: fadeIn 0.5s ease-in forwards;
	img {
		opacity: 0; 
		animation: fadeIn 0.5s ease-in forwards;
	}
}

@keyframes fadeIn {
	from {
			opacity: 0; /* Start from fully transparent */
	}
	to {
			opacity: 1; /* Fade to fully opaque */
	}
}

.karaoke-badge {
	color: #000;
	background: #fff;
	border-radius: 100%;
	position: absolute;
	bottom: 0;
	right: 0;
	font-size: 19px;
	z-index: 1;
	width: 20px;
	height: 20px;
	line-height: 0;
	text-align: center;
	margin: 4px;
}

.karaoke-badge-row {
	color: #000;
	background: #fff;
	border-radius: 100%;
	bottom: 0;
	right: 0;
	font-size: 19px;
	z-index: 1;
	width: 20px;
	height: 20px;
	line-height: 0;
	text-align: center;
	margin: 4px;
}

tr.gu-mirror {
	display: none;
}
 
tr.gu-transit {
	opacity: 1;
	color:#000;
	box-shadow: 0px 0px 10px rgba(0,0,0,0.5);
	td {
		background-color: rgba(109, 191, 137, 0.29) !important;
	}
}