/******************************
*
*	Component: Search
*
******************************/


.search {
	position: relative;
}

.tt-menu {
	border:1px solid $border;
	width: 100%;
	max-height: 400px;
	overflow: scroll;
}

.twitter-typeahead {
	display: block!important;
}

.tt-dataset {
	text-align: left;
	background-color: #FFF;
	width: 100%;
	.badge-explicit {
		width: 10px;
		height: 10px;
		&:before {
			content: "\65";
			font-family: 'tunedicons';
		}
	}
	.badge-tag {
		padding: 0.4em 0.5em!important;
	}
	div {
		font-size: 90%;
		padding: 5px;
		border-bottom: 1px solid #EEE;
		cursor: pointer;
		&:hover{
			background-color:#EFEFEF;
		}

		&.tt-cursor{
			background-color: #EFEFEF;
		}
	}
}

.typeahead .stream-false {
	color: #EFEFEF;
}
