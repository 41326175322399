/******************************
*
*	Component: Stats
*
******************************/

.dashboard {
  margin: 20px 20px;
  &-item {
    margin: 0 0 15px 0;
  }
}

.stat-block {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 6px rgb(167 167 167 / 32%);
  margin: 0 0 15px;
  &-content {
    padding: 15px;
  }
  .icon:before {
    font-size: 20px;
  }
}

.statscounter {
  line-height: 1.5;
  font: {
    size: 14px;
    weight: 400;
  }
  .stat-icon {
    float: right!important;
    color: #909090;
  }
  .stat-title {
    color: #52575b;
    text-transform: uppercase;
    margin: 2px 0 0 0;
    font: {
      size: 0.65rem;
      weight: 500;
    }
  } 
  .stat-count {
    font: {
      size: 1.8em;
      weight: 500;
    }
  }
  .stat-link {
    display: block;
    color: #909090;
    font: {
      size: 12px;
      weight: 400;
    }
    .arrow {
      font: normal normal normal 14px/1 FontAwesome;
      float: right;
      transform: rotate(90deg);
      padding: 4px 0;
      color: #909090;
      font: {
        size: 10px;
      }
      &:before {
        content: "\f077";
      }
    }
  }
}
