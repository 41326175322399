@charset "UTF-8";
/******************************
*
*	Main
*
******************************/
/******************************
*
*	Variables
*
******************************/
/******************************
*
*	Mixins
*
******************************/
/******************************
*
*	Helpers
*
******************************/
div.inline {
  display: inline;
}

nowrap {
  white-space: nowrap;
}

.vert-middle {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.padded {
  padding: 20px;
}

.dark_overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  opacity: 0.2;
}

.verified_tick {
  background-color: #4CAF50;
  border-radius: 50%;
  color: #FFF;
  font-size: 60%;
  padding: 4px 4.5px;
  font-weight: bold;
  position: absolute;
  top: 13px;
  left: 57px;
  height: 17px;
  width: 17px;
}

.spinthrob {
  animation: spinthrob 0.5s infinite linear;
}

@keyframes spinthrob {
  0% {
    transform: scale(1) rotate(0deg);
  }
  50% {
    transform: scale(1.5) rotate(45deg);
  }
  100% {
    transform: scale(1) rotate(89.5deg);
  }
}
.spin {
  animation: spin 2s infinite linear;
  background-color: transparent !important;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
.nowrap {
  white-space: nowrap;
}

.wrap-all {
  word-break: break-all;
}

.table-fixed-wide {
  table-layout: fixed;
  width: 100%;
}

.cell-nowrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.row.no-pad {
  margin-right: 0;
  margin-left: 0;
}

.row.no-pad > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.code {
  font-family: Consolas, Lucida Console, monospace;
  font-size: 80%;
}

.hide {
  display: none;
  visibility: hidden;
}

b, strong,
.strong {
  font-weight: 500;
}

.vertically-align {
  align-items: center;
  justify-content: center;
}

#page_header {
  position: fixed;
  top: 0;
  background: rgb(240, 90, 36);
  background: -moz-linear-gradient(140deg, rgb(240, 90, 36) 0%, rgb(238, 84, 65) 70%);
  background: -webkit-linear-gradient(140deg, rgb(240, 90, 36) 0%, rgb(238, 84, 65) 70%);
  background: linear-gradient(140deg, rgb(240, 90, 36) 0%, rgb(238, 84, 65) 70%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f05a24",endColorstr="#ee5441",GradientType=1);
  height: 46px;
  width: 100%;
  z-index: 20;
  transition: all 0.1s ease;
  box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.35);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#page_header .logo-container {
  height: 46px;
  padding: 0 20px 0 20px;
  max-width: 220px;
  border-right: 1px solid rgba(250, 250, 250, 0.2);
  font-size: 1.5rem;
  font-weight: bold;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
}
#page_header .logo {
  background-image: url("/images/logo.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 150px;
  height: 28px;
  position: relative;
}
#page_header .spinner {
  position: absolute;
  top: 7px;
  left: 10px;
  width: 30px;
  height: 30px;
  display: inline-block;
  border-radius: 50%;
  background-color: rgba(250, 250, 250, 0.4);
  font-size: 19px;
  transition: background-color 0.3s ease;
  text-align: center;
  line-height: 31px;
  color: #ffffff;
}
#page_header .store {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  gap: 10px;
}
#page_header .storename {
  margin: 0 0 0 20px;
  max-width: 250px;
  font-weight: 600;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  letter-spacing: 0px;
  color: #ffffff;
  font-size: 19px;
}
#page_header .package {
  position: relative;
  left: 0;
}
#page_header .header-nav {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  margin: 0 10px 0 0;
}
#page_header .header-nav-button {
  align-items: center;
  justify-content: flex-start;
  color: #ffffff;
  font-weight: 500;
  z-index: 1;
  position: relative;
  cursor: pointer;
}
#page_header .header-nav-button-container {
  color: #ffffff;
  font-weight: 600;
}
#page_header .header-nav-button-inner {
  padding: 3px 28px 3px 10px;
  border-radius: 5px;
  line-height: 1.3;
  height: 36px;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease-in-out, color 0.1s ease-in-out;
}
#page_header .header-nav-button-inner:hover {
  background-color: rgba(0, 0, 0, 0.2);
}
#page_header .header-nav-button .fa {
  font-size: 20px;
  color: #ffffff;
  margin-top: 1px;
}
#page_header .header-nav-button a {
  padding: 7px;
  width: auto !important;
  height: initial !important;
  border-radius: 5px;
  transition: background-color 0.3s ease-in-out, color 0.1s ease-in-out;
}
#page_header .header-nav-button a:hover {
  background-color: rgba(0, 0, 0, 0.2);
}
#page_header .header-nav-button .chevron {
  color: #ffffff;
  position: absolute;
  right: 10px;
  top: 47%;
  font-size: 12px;
  transform: translateY(-50%);
}
#page_header .client-package {
  border-radius: 100%;
  width: 15px;
  height: 15px;
  display: inline-block;
}
#page_header .client-package.snap {
  background-image: url("/images/badge-silver.png");
  background-repeat: no-repeat;
  background-size: cover;
}
#page_header .client-package.stretch {
  background-image: url("/images/badge-gold.png");
  background-repeat: no-repeat;
  background-size: cover;
}
#page_header .client-package.extreme {
  background-image: url("/images/badge-platinum.png");
  background-repeat: no-repeat;
  background-size: cover;
}
#page_header .profile-details {
  font-size: 0.85em;
  flex-direction: column;
  align-items: flex-start;
}
#page_header .profile-details small {
  font-size: 10px;
  display: block;
  color: rgba(250, 250, 250, 0.7);
}
#page_header .environment {
  text-transform: capitalize;
}
#page_header button.btn-link {
  color: #FFF;
}

#page_menu {
  transition: left 0.1s ease;
  z-index: 20;
  position: fixed;
  margin-top: 46px;
  top: 0;
  left: -240px;
  bottom: 0;
  width: 220px;
  padding-top: 20px;
  padding-bottom: 80px;
  height: auto !important;
  overflow-x: hidden;
  overflow-y: auto;
  background: #1a1a1a;
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.16);
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.16);
}
#page_menu hr {
  margin: 0px;
}
#page_menu .menu-header {
  padding: 8px !important;
  display: block;
  padding-left: 20px;
  color: #c2c2c2 !important;
  font-weight: 500;
  transition: background-color 0.3s ease-in-out, color 0.1s ease-in-out;
  font-size: 15px;
  border-radius: 3px;
  margin: 8px;
}
#page_menu .menu-header span.fa {
  margin-right: 5px;
}
#page_menu .menu-header .chevron {
  transition: transform 0.2s ease;
  transform: rotate(180deg);
  line-height: 27px;
  font-size: 12px;
}
#page_menu .menu-header.collapsed {
  border-left: none;
  background-color: transparent;
  color: #c2c2c2 !important;
}
#page_menu .menu-header.collapsed a {
  color: #c2c2c2 !important;
  transition: background-color 0.3s ease-in-out, color 0.01s ease-in-out;
}
#page_menu .menu-header:hover {
  color: #ffffff !important;
  text-decoration: none;
}
#page_menu .menu-header:hover a {
  color: #ffffff !important;
  text-decoration: none;
}
#page_menu .menu-header:hover .chevron {
  display: inline-block;
}
#page_menu .menu-header.active {
  color: #ffffff !important;
}
#page_menu .menu-header.title {
  color: #ffffff !important;
}
#page_menu.show {
  left: 0;
}
#page_menu.show #page_header {
  left: 0px;
}
#page_menu .wrapper {
  width: 220px;
}
#page_menu .menu-admin {
  margin: 30px 0;
  border-top: 1px solid rgba(250, 250, 250, 0.2);
  padding: 10px 0;
}
#page_menu .menu-icon {
  text-align: center;
  width: 19px;
}
#page_menu nav {
  font-size: 87%;
  margin: -10px 8px 15px;
  border-radius: 3px;
}
#page_menu nav .nav-link {
  color: #c2c2c2;
  border-radius: 3px;
  padding: 6px 9px;
  margin: 0 6px 0px 33px;
  font-weight: 500;
  -webkit-transition: background-color 0.5s ease-out;
  -moz-transition: background-color 0.5s ease-out;
  -o-transition: background-color 0.5s ease-out;
  transition: background-color 0.5s ease-out;
}
#page_menu nav .nav-link span {
  padding-right: 5px;
}
#page_menu nav .nav-link:hover {
  color: #ffffff;
}
#page_menu nav .nav-link.active {
  background: transparent;
  color: #ffffff;
  font-weight: 700;
}
#page_menu nav .nav-link.active span {
  color: #ffffff;
}
#page_menu nav .nav-link.active span:before {
  color: #ffffff;
}

#menu-toggle {
  color: #ffffff;
  background: none;
  font-size: 1.8em;
  border-radius: 0;
  padding: 5px 12px;
  height: 46px;
  width: 45px;
  transition: left 0.1s ease;
  position: fixed;
  left: 0px;
  z-index: 30;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 17px;
  border: none;
}

#menu-toggle .bar {
  width: 25px;
  height: 2px;
  background-color: #FFF;
  border-radius: 4px;
  transition: all 0.3s ease;
}

#menu-toggle .top {
  transform-origin: left center;
}

#menu-toggle .bottom {
  transform-origin: right center;
}

#menu-toggle.open .top {
  transform: rotate(45deg) translateY(-7px) translateX(0px);
}

#menu-toggle.open .bottom {
  transform: rotate(-45deg) translateY(-12px) translateX(9px);
}

#menu-toggle.open {
  left: 0px;
}

/******************************
*
*	Layout
*
******************************/
html, body {
  overflow: auto;
}

body {
  background-color: #FFF;
  color: #52575b;
  font-weight: 400;
  height: 100%;
  font-size: 0.95rem;
}
body.notips .tips {
  display: none;
}
body .tips {
  position: relative;
}
body .tips:before {
  position: absolute;
  font-family: "FontAwesome";
  content: "\f05a";
}
body .tips p {
  margin: 0 0 0 20px;
}
body .tips p:last-child {
  margin-bottom: 0;
}

a {
  color: #52575b;
  cursor: pointer;
  font-weight: 400;
}
a:hover {
  color: #52575b;
}

hr {
  margin: 20px 0 0 0;
}

label {
  font-weight: 500;
}

#framework {
  position: relative;
}

.header-menu {
  -webkit-box-shadow: 0 2px 4px rgba(50, 50, 50, 0.2);
  box-shadow: 0 2px 4px rgba(50, 50, 50, 0.2);
  width: 230px;
  max-height: 356px;
  overflow-y: scroll;
  border-radius: 5px;
  margin: 0;
  position: absolute;
  top: 42px;
  right: 0;
}
.header-menu .menu-row {
  background-color: #ffffff;
  color: #ffffff;
  margin-top: -8px;
  padding: 10px 0 2px;
  border-radius: 3px;
  position: relative;
}
.header-menu .nav-pills {
  background-color: #ffffff;
}
.header-menu .groups {
  background: #f4f4f4;
  color: #333333;
  padding: 5px 0;
  position: relative;
}
.header-menu .groups .nav-link {
  padding: 2px 10px !important;
}
.header-menu .groups p {
  padding: 10px;
}
.header-menu hr {
  padding: 0;
  margin: 0;
}
.header-menu .nav-link {
  color: #5a5a5a !important;
  padding: 8px 5px !important;
  font-weight: 600;
  transition: background-color 0.3s ease-in-out, color 0.1s ease-in-out;
  font-size: 14px;
  border-radius: 3px;
  margin: 6px;
}
.header-menu .nav-link .chevron {
  font-size: 12px;
  margin: 3px 0 0;
}
.header-menu .nav-link:hover {
  color: #f05a24 !important;
  background: #f4f4f4 !important;
}
.header-menu .nav-link .menu-icon {
  text-align: center;
  width: 19px;
  margin-right: 5px;
}
.header-menu .nav-link span:before {
  color: #5a5a5a;
}

#status {
  position: fixed;
  bottom: 0px;
  width: 220px;
  margin-bottom: 0px;
  font-size: 60%;
  border-radius: 0;
  background-color: #f5f5f5;
  border: 0;
  color: #888888;
}

.changelog li {
  margin-bottom: 10px;
}

#page_section {
  background-color: #fafbfe;
  position: relative;
  top: 46px;
  bottom: 0;
  height: auto !important;
  padding-bottom: 100px;
  visibility: hidden;
}
#page_section .section-content {
  display: inline-block;
  width: 100%;
  height: 100vh;
}

.fullwidth {
  width: 100% !important;
}

#gutter {
  display: none;
  position: fixed;
  left: 50%;
  background-color: transparent;
  background-image: url("/images/gutter.png");
  background-repeat: no-repeat;
  background-position: 50%;
  height: 100%;
  width: 10px;
  z-index: 100;
  cursor: ew-resize;
  top: 46px;
}
#gutter:hover {
  background-color: rgb(118, 126, 130);
}

#page_item {
  display: none;
  background-color: #ebeef2;
  overflow: hidden;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 10px;
  text-align: center;
  z-index: 3;
  overflow-y: auto;
}
#page_item.show {
  display: inline-block !important;
}

.item-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.item-content {
  height: 100%;
  overflow-y: auto;
  background-color: #FFF;
  display: inline-block;
  text-align: left;
  width: 95%;
  margin-top: 60px;
  position: relative;
  border-radius: 3px;
}

button.item-close, button.item-reload {
  z-index: 9000;
  position: fixed;
  right: 3px;
  top: 53px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 3px solid #e2e2e2;
  color: #6a6a6a;
  background-color: #FFF;
  padding-left: 7px;
}
button.item-close:hover, button.item-reload:hover {
  background-color: #EFEFEF;
}
button.item-close.item-reload, button.item-reload.item-reload {
  display: none;
}

.btn {
  border-radius: 1.5rem;
}
.btn-primary {
  background: #f05a24;
  border: #f05a24;
}
.btn-primary:hover {
  background: #f05a24;
  border: #f05a24;
}
.btn.fa {
  padding: 8px;
  min-width: 33px;
}
.btn.btn-primary {
  color: #FFF !important;
}
.btn.btn-outline {
  border: 1px solid #b2b2b2;
  border-radius: 1.5rem;
  background: #fff;
  padding: 5px 10px;
  color: #5a5a5a;
}
.btn.btn-login {
  background: #f05a24;
  border-color: #f05a24;
}
.btn.btn-login:hover, .btn.btn-login:active, .btn.btn-login:focus {
  background: #f37e54;
  border-color: #f37e54;
}
.btn.btn-showkey {
  outline: none;
  border: none;
  color: #5a5a5a;
  cursor: pointer;
}

a.btn.btn-outline {
  border: 1px solid #b2b2b2;
  border-radius: 3px;
  background: #ffffff;
  color: #5a5a5a;
}

.input-group-addon .btn-link {
  padding: 0px;
}

.panel-heading .float-right button {
  margin-top: -8px;
}

.editor-container {
  margin: 12px 0 15px 0;
  padding: 8px;
  min-height: 100vh;
}

.heading-container {
  display: inline-block;
  width: 100%;
}
.heading-container h2 {
  font-weight: 500;
}

.health-check-list {
  list-style: none;
  margin: 0;
  padding: 5px;
}
.health-check-list li {
  padding: 3px 0;
}

#tab_mnd_search .fixed-table-pagination {
  display: none !important;
}

.fixed-table-pagination .btn-secondary {
  font-size: 0.7rem;
  padding: 1px 8px;
}

.lead {
  font-size: 1.1rem;
}

.dragfile .dropzone .lead {
  font-size: 0.9rem;
}

.advanced-search-details {
  text-align: center;
  font-size: 0.9rem;
  line-height: 1.1;
}
.advanced-search-tags {
  line-height: 1;
}

.preview {
  position: relative;
}

.overlay:before {
  content: "";
  position: fixed;
  background-color: rgba(0, 0, 0, 0.3);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 15;
}

.ml-3, .mx-3 {
  margin-left: 0.5rem !important;
}

.border-bottom {
  border-bottom: 1px solid rgb(235, 235, 235) !important;
  padding: 6px 0;
}
.border-bottom:nth-last-child {
  border-bottom: 0px !important;
}

.nomargin {
  margin: 0;
}

.login-banner {
  background: rgb(240, 90, 36);
  background: -moz-linear-gradient(140deg, rgb(240, 90, 36) 0%, rgb(238, 84, 65) 70%);
  background: -webkit-linear-gradient(140deg, rgb(240, 90, 36) 0%, rgb(238, 84, 65) 70%);
  background: linear-gradient(140deg, rgb(240, 90, 36) 0%, rgb(238, 84, 65) 70%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f05a24",endColorstr="#ee5441",GradientType=1);
  min-height: 150px;
  position: relative;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.login-logo {
  width: 158px;
  height: 62px;
  background-image: url("/images/login-logo.png");
  background-repeat: no-repeat;
  background-size: contain;
  margin: 0;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#playlist_to_csv {
  visibility: collapse;
}

.forgot-password {
  color: #666666;
}

.vertical-alignment-helper {
  display: table;
  height: 100%;
  width: 100%;
  pointer-events: none;
}

.vertical-align-center {
  display: table-cell;
  vertical-align: middle;
  pointer-events: none;
}

.modal-content {
  width: inherit;
  max-width: inherit;
  height: inherit;
  margin: 0 auto;
  pointer-events: all;
}

.csv .pagination-detail {
  display: none;
}

.close {
  font-weight: initial !important;
  text-shadow: none !important;
  font-size: 1.35rem !important;
}

body > .alert {
  padding: 10px 15px !important;
}
body > .alert .text-center {
  text-align: center;
}

.two-factor-form {
  display: flex;
  justify-content: center;
}
.two-factor-form input {
  margin: 0 0.5rem;
  padding: 0.5rem;
  border: 0.1rem solid #dddddd;
  width: 50px;
  height: 50px;
  text-align: center;
  border-radius: 5px;
  font-size: 2.5rem;
  line-height: normal;
}

a.nav-item.nav-link {
  padding: 6px;
  font-size: 0.8rem;
}

.card-header {
  padding: 0.45rem 1rem;
  line-height: 30px;
  font-weight: 500;
}

.nav-pills .nav-link.active {
  background-color: #2d2d2d;
}

nav.nav.nav-pills.nav-fill.border.border-primary {
  border-color: #939393 !important;
}

.fixed-table-container tbody td .th-inner, .fixed-table-container thead th .th-inner {
  padding: 8px 8px 8px 8px !important;
}

.vertical-center {
  display: flex;
  align-items: center;
}

#loading {
  height: 100vh;
  width: 100vw;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #f05a24;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
.inherit-width {
  width: inherit;
}

.small, small {
  font-size: 85%;
}

#bulkactions .dropdown-menu {
  min-width: 14rem !important;
}
#bulkactions .dropdown-menu a.nav-link {
  font-size: 14px;
  color: #52575b;
  font-weight: 400;
  padding: 4px 15px;
}

.page-loading {
  top: 50%;
  position: relative;
  filter: brightness(220%);
}

.row.display-flex {
  display: flex;
  flex-wrap: wrap;
}

.row.display-flex > [class*=col-] {
  display: flex;
  flex-direction: column;
}

.tooltip .tooltip-inner {
  text-align: left;
}

/******************************
*
*	Breakpoints
*
******************************/
@media (max-width: 64em) {
  #page_item, #page_section {
    width: 100% !important;
  }
}
@media (max-width: 37.5em) {
  #menu-toggle {
    width: 45px;
    position: fixed;
  }
  #edit_bar {
    top: 0 !important;
  }
  #page_section {
    height: 100vh;
  }
  #page_menu {
    top: 0;
    padding-top: 0;
  }
  .logo-container {
    display: none !important;
  }
  .login-logo {
    transform: inherit;
    top: 39px;
    left: 30px;
    width: 130px;
  }
  .login-banner {
    min-height: 120px;
  }
  .store {
    margin-left: 40px;
  }
  .store .storename {
    font-size: 16px !important;
  }
  #page_header .storename {
    margin: 0 0 0 10px !important;
  }
  #page_header .header-nav {
    gap: 5px !important;
  }
  #page_header .profile-details {
    font-size: 0.75em !important;
  }
  #page_header .profile-details small {
    font-size: 8px !important;
  }
  .header-nav-button .chevron {
    right: 10px !important;
  }
}
@media (min-width: 37.5em) {
  #page_item {
    padding: 40px;
  }
  .logo {
    margin: 0 0 0 30px;
  }
  button.item-close,
  button.item-reload {
    right: 5px;
  }
  #page_header .spinner {
    display: none;
  }
}
@media (min-width: 50em) {
  #page_item {
    padding: 40px;
  }
  button.item-close,
  button.item-reload {
    right: 10px;
  }
}
@media (min-width: 64em) {
  #page_menu {
    position: fixed;
  }
  #page_header .spinner {
    display: none;
  }
  #page_section {
    position: fixed;
    overflow-y: scroll;
    width: 50%;
    padding-left: 0px;
    left: 0;
    height: 100%;
  }
  #page_item {
    height: calc(100vh - 48px) !important;
    display: inline-block;
    opacity: 1;
    position: fixed;
    top: 47px;
    left: auto;
    right: 0;
    overflow-y: auto;
    width: 50%;
    padding: 0;
  }
  .item-content {
    overflow: visible;
    height: auto;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  button.item-close {
    display: none;
  }
  button.item-reload {
    right: 0.5%;
    height: 40px;
    width: 40px;
  }
  #gutter {
    display: block;
  }
  #framework.wideMode #page_section {
    width: 15%;
    overflow: hidden;
    white-space: nowrap;
    opacity: 0.2;
  }
  #framework.wideMode .item-resize {
    background-color: #f05a24;
    color: #FFF;
  }
}
@media (min-width: 70em) {
  #page_menu {
    display: inline-block;
    opacity: 1;
    left: 0;
  }
  .logo {
    margin: 0;
  }
  #page_header {
    left: 0px;
  }
  #menu-toggle {
    display: none;
  }
  #page_section {
    padding-left: 220px;
    top: 46px;
  }
  #page_header .spinner {
    display: block;
  }
}
/******************************
*
*	Component: Modals
*
******************************/
#page_modal {
  z-index: 10000;
}

.modal {
  z-index: 10050;
  width: 100%;
  backdrop-filter: blur(3px);
}
.modal-header {
  display: inline-block;
  padding: 1rem 1.2rem;
  border-bottom: 0;
}
.modal-header .close {
  padding: 0;
  right: 0;
  top: 0;
  margin: 15px !important;
  position: absolute;
  background: #d8d8d8;
  border-radius: 100%;
  height: 35px;
  width: 35px;
}
.modal-header .close span {
  color: #000000;
  position: relative;
  top: -2px;
  font-size: 20px;
}
.modal-body {
  margin: 0.2rem;
}
.modal-title {
  max-width: 90%;
}
.modal-title .text-info {
  color: #31708f !important;
}
.modal-title.muted {
  color: #C0C0C0;
  text-transform: uppercase;
  font-size: 20px;
}
.modal-backdrop.show {
  opacity: 0.6 !important;
}
.modal-footer {
  border-top: 0;
}

@media (min-width: 992px) {
  .modal-lg {
    max-width: 700px;
  }
}
/******************************
*
*	Component: Alerts
*
******************************/
body > .alert {
  border: 0;
}
body > .alert.alert-success {
  background-color: #28a745 !important;
  color: #FFF !important;
}
body > .alert.alert-info {
  background-color: #15bed8 !important;
  color: #FFF !important;
}
body > .alert.alert-danger {
  background-color: #dc3545 !important;
  color: #FFF !important;
}
body > .alert.alert-warning {
  background-color: #ffc107 !important;
  color: #333 !important;
}

.alert {
  border: 0;
  font-size: 0.85rem;
  font-weight: 400;
  line-height: 1.4;
  padding: 0.55rem 0.85rem !important;
}
.alert-info {
  color: #52575b !important;
  background-color: #ededed !important;
  border: 0px;
}
.alert-info.tips {
  color: #52575b !important;
  background-color: #d3eeff !important;
  border-color: #d3eeff !important;
  font-size: 0.85rem;
  font-weight: 400;
  line-height: 1.4;
  padding: 0.55rem 0.85rem !important;
}
.alert-unsaved {
  background: #F2BC58;
  color: #3f3f3f;
  padding: 0.25rem 0.85rem !important;
  margin: 10px 15px;
}
.alert-unsaved p {
  margin: 0 30px;
  font-size: 12px;
  text-align: center;
}
.alert-unsaved .close {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 10px;
  margin: auto;
  font-size: 16px !important;
}

.alert-overdue {
  background: #ffc107;
  color: #333333;
  padding: 10px 15px;
  font-size: 12px;
  border-radius: 5px;
  margin: 0 0 20px 0;
  transition: background-color 0.5s ease;
}
.alert-overdue span {
  font-size: 13px;
  display: block;
  font-weight: 500;
}

.alert-suspended {
  background: #dc3545;
  color: #ffffff;
  padding: 10px 15px;
  font-size: 12px;
  border-radius: 5px;
  margin: 0 0 20px 0;
  transition: background-color 0.5s ease;
}
.alert-suspended span {
  font-size: 13px;
  display: block;
  font-weight: 500;
}

.alert-inactive {
  background: #b1b1b1;
  color: #ffffff;
  padding: 10px 15px;
  font-size: 12px;
  border-radius: 5px;
  margin: 0 0 20px 0;
  transition: background-color 0.5s ease;
}
.alert-inactive span {
  font-size: 13px;
  display: block;
  font-weight: 500;
}

/******************************
*
*	Components: Headings
*
******************************/
h2 {
  font-weight: 400;
  font-size: 1.6rem;
  padding-left: 20px;
  margin: 20px 20px 0 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  line-height: 1.2;
}

h3 {
  font-size: 1.3rem !important;
  font-weight: 400 !important;
}

.profile_header {
  display: inline-block;
  width: 100%;
}
.profile_header img {
  margin: 12px 12px 0 12px;
  float: left;
  height: 60px;
  opacity: 0;
  animation: fadeIn 0.5s ease-in forwards;
}
.profile_header p.item-type {
  position: inherit;
  border-left: none;
  margin: 10px 0 0;
  font-weight: 400;
}
.profile_header h2 {
  border-left: none;
  margin: 0px;
  padding-left: 0px;
  position: relative;
}
.profile_header h2 .title {
  padding-right: 10px;
  max-width: 70%;
  display: inline-block;
  font-size: 1.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  line-height: 1.2;
}
.profile_header h2 .priority {
  position: absolute;
  display: inline-block;
  top: 4px;
}

p.item-type {
  padding-left: 20px;
  padding-top: 5px;
  margin: 20px 20px 0 0;
  position: absolute;
  white-space: nowrap;
  font-size: 12px;
  top: -17px;
  color: #f05a24;
  font-weight: 400;
}

.item-content h3 {
  margin: 20px 20px 0 -10px;
  padding-left: 10px;
  font-weight: 100;
}

.heading-buttons {
  margin: 20px 20px 0 0;
}
.heading-buttons button {
  border: 1px solid #6c757d;
  background: 0;
  color: #6c757d;
}

/******************************
*
*	Component: Buttons
*
******************************/
.btn-float-top-right {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 3em;
}

button.btn-help {
  border-radius: 50%;
  background-color: #03a9f4;
  color: #FFF;
  height: 25px;
  width: 25px;
  overflow: hidden;
  font-size: 16px;
  line-height: 24px;
  padding: 0px;
}
button:focus {
  outline: none !important;
}

img[data-image] {
  cursor: pointer;
}

.img-thumbnail {
  padding: 1px;
  border: 0;
}

.artist-browse {
  margin: 0 0 10px 0;
  padding: 0;
}
.artist-browse li {
  display: inline-block;
  cursor: pointer;
}
.artist-browse li a {
  border-radius: 3px;
  display: inline-block;
  width: 20px;
  font-size: 13px;
  font-weight: 600;
  height: 20px;
}
.artist-browse li a.active {
  background-color: #f05a24;
  color: #FFF;
}
.artist-browse li a:hover {
  background-color: #dedede;
}

.month_text, .year_text {
  min-width: 80px;
  display: inline-block;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}

.lyrics {
  padding: 3px 5px;
  font-size: 8px;
  border: 1px solid #9b9b9b;
  border-radius: 3px;
  font-weight: 900;
  color: #9b9b9b;
}

.btn {
  white-space: initial;
  font-size: 0.85rem;
  font-weight: 500;
  padding: 0.275rem 1rem;
}
.btn-primary, .btn-secondary {
  border-radius: 1.5rem;
}
.btn-copy {
  border-radius: 100%;
  border: 1px solid #d4d4d4;
  font-size: 10px;
  cursor: pointer;
  margin: 0 0 0 5px;
  height: 30px;
  width: 30px;
  text-align: center;
}
.btn-copy:hover {
  filter: brightness(95%);
}

.play-btn {
  position: absolute;
  border-radius: 100%;
  height: 40px;
  width: 40px;
  text-align: center;
  color: #ffffff;
  background: rgba(50, 50, 50, 0.8);
  border: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: auto;
  cursor: pointer;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
.play-btn:hover {
  background: rgba(0, 0, 0, 0.8);
  -webkit-transition: background-color 0.3s ease;
  -moz-transition: background-color 0.3s ease;
  -o-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
}

.dropdown-menu.options {
  margin: 0;
  padding: 0;
}
.dropdown-menu.options .dropdown-item {
  color: #5a5a5a;
  font-size: 0.9em;
  padding: 10px 15px !important;
  border-top: 1px solid #f4f4f4;
}
.dropdown-menu.options .dropdown-item .dropdown-icon {
  width: 20px;
  text-align: center;
  margin: 4px 10px 0 0;
  float: left;
}
.dropdown-menu.options .dropdown-item .dropdown-link {
  display: block;
  margin-left: 30px;
  font-size: 12px;
  color: #888888;
}
.dropdown-menu.options .dropdown-item .dropdown-link a {
  font-weight: initial;
  color: #888888;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 200px;
}
.dropdown-menu.options .dropdown-item .dropdown-link .btn-sm.btn-copy {
  height: 20px;
  width: 20px;
  font-size: 8px;
  padding: 0;
}
.dropdown-menu.options .dropdown-item a {
  display: block !important;
  font-weight: 500;
}
.dropdown-menu.options .dropdown-item.active, .dropdown-menu.options .dropdown-item:active {
  color: initial;
  background-color: #f4f4f4;
  border-radius: 5px;
}

.safe-delete-yes {
  background: #f05a24 !important;
  border-color: #f05a24 !important;
}

.safe-delete-no {
  background-color: #6c757d !important;
  border-color: #6c757d !important;
}

.btn-round {
  display: inline-block;
  background-color: #d4d4d4;
  height: 20px;
  width: 20px;
  line-height: 20px;
  border-radius: 50%;
  border: none;
  margin: 0 0 0 5px;
  font-size: 13px;
  font-weight: 600;
  color: #555555;
  text-align: center;
  padding: 0;
}

.btn-round-preview {
  border-radius: 50%;
}

.btn-primary, .btn-primary:hover {
  background: #f05a24 !important;
  border: 2px solid #f05a24 !important;
}

#editor_save span {
  pointer-events: none;
}

/******************************
*
*	Components: Forms
*
******************************/
.input-group-addon.small {
  font-size: 80%;
  font-weight: lighter;
}

.form-control.error {
  border-color: red;
}

textarea.form-control {
  padding-right: 40px;
}

textarea, pre {
  -moz-tab-size: 2;
  -o-tab-size: 2;
  tab-size: 2;
}

.form-control {
  border-radius: 1.5rem;
  font-size: 0.95rem;
  padding: 0.375rem 1rem;
}

label {
  margin-bottom: 0.3rem;
}

label.error {
  color: red;
  font-weight: 400;
  font-size: 12px;
}

label small {
  font-weight: normal;
  opacity: 0.8;
}

.multiselect-container {
  width: 100%;
}
.multiselect-container .checkbox {
  padding: 4px 15px;
  width: 100%;
}
.multiselect-container a {
  color: #5a5a5a !important;
}

.multiselect-container > li {
  width: 50% !important;
  float: left !important;
}

.multiselect-container > li:first-child {
  width: 100% !important;
  float: none !important;
}

.form-control-select {
  max-height: 100%;
  width: 100%;
  display: block;
  padding: 0.375rem 1rem;
  font-size: 0.95rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 1.5rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  text-align: left;
}

.disabled {
  pointer-events: none;
  outline: none;
  background-color: #e9ecef;
  opacity: 1;
}

.radio-btn {
  padding: 0 20px 0 0;
}

.search input.form-control {
  padding-left: 35px;
}

.search:after {
  content: "\f002";
  position: absolute;
  top: 30%;
  left: 12px;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  color: #495057 !important;
}

.search-input {
  position: relative;
}
.search-input:after {
  content: "\f002";
  position: absolute;
  top: 30%;
  left: 12px;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  color: #495057 !important;
}
.search-input .form-control {
  padding-left: 35px;
  border-radius: 1.5rem;
}

.artist-input {
  position: relative;
}
.artist-input:after {
  content: "å";
  position: absolute;
  top: 25%;
  left: 12px;
  font-size: inherit;
  color: #495057 !important;
}
.artist-input .form-control {
  padding-left: 35px;
}

.date-input {
  position: relative;
}
.date-input:after {
  content: "\f133";
  position: absolute;
  top: 30%;
  left: 12px;
  z-index: 3;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  color: #495057 !important;
}
.date-input .form-control {
  padding-left: 35px;
}

.no-field {
  border: 0;
  background: transparent !important;
  padding: 2px;
  font-size: 14px;
}
.no-field:focus {
  outline: none !important;
  box-shadow: 0 0 0 0;
}

.form-check-input {
  margin-top: 0.25rem !important;
}

.ui-slider-horizontal {
  height: 6px;
}

.ui-slider-horizontal .ui-slider-range {
  background: #666666;
}

.ui-slider-handle {
  top: -7px !important;
  border-radius: 100%;
}

.error-message {
  color: red;
  font-size: 14px;
  margin-top: 10px;
}

.pwd-restrictions {
  list-style: none;
  padding: 0;
}

.pwd-restrictions li {
  padding: 0 0 0 20px;
  margin: 0 0 0 5px;
  line-height: 2em;
}

.pwd-restriction-checked {
  position: relative;
}
.pwd-restriction-checked:before {
  position: absolute;
  font-family: "FontAwesome";
  content: "\f00c";
  left: 0;
  color: #28a745;
}
.pwd-restriction-checked .pwd-cross {
  display: none;
}

.pwd-cross {
  margin: 0 10px 0 -20px;
  color: #dc3545;
}

.multiselect-filter label {
  color: #495057 !important;
}

.underline-link {
  border-bottom: 1px solid;
}

.instructions {
  padding: 20px 0 0;
}

.loader {
  position: relative;
  width: 100%;
  height: 50px;
}
.loader .container {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: -0.5px;
  width: 200px;
  height: 100px;
}
.loader .container .spinner div {
  border: 6px solid #444444 !important;
  border-color: #444444 transparent transparent transparent !important;
}
.loader .container .loading-text {
  text-align: center;
  text-transform: initial;
  margin: 0 0 0;
  padding: 0 0 50px 0;
}

.priority-item {
  height: 20px;
  padding: 6px 3px 10px 3px;
  display: inline-block;
}

select#prioritySelect {
  border: 0;
  font-size: 9px;
  padding: 0;
  font-weight: bold;
  height: 20px;
  border-radius: 3px;
  text-align: center;
  outline: none;
}

.tag-score-select {
  font-size: 12px;
  padding: 0;
  font-weight: bold;
  height: 30px;
  border-radius: 3px;
  text-align: center;
  outline: none;
  min-width: 80px;
}

input.form-control.multiselect-search {
  border-top-left-radius: 30px !important;
  border-bottom-left-radius: 30px !important;
  border-top-right-radius: 30px !important;
  border-bottom-right-radius: 30px !important;
}

.spinner {
  display: inline-block;
  position: relative;
  width: 50px;
  height: 50px;
}
.spinner div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 40px;
  height: 40px;
  margin: 6px;
  border: 6px solid #5d5d5d;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background-clip: padding-box;
  -webkit-animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  -moz-animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  -ms-animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #5d5d5d transparent transparent transparent;
}
.spinner div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
  -moz-animation-delay: -0.45s;
  -ms-animation-delay: -0.45s;
}
.spinner div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
  -moz-animation-delay: -0.3s;
  -ms-animation-delay: -0.3s;
}
.spinner div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
  -moz-animation-delay: -0.15s;
  -ms-animation-delay: -0.15s;
}

@-webkit-keyframes spinner {
  0% {
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-ms-keyframes spinner {
  0% {
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/******************************
*
*	Component: Tables
*
******************************/
table {
  font-size: 0.9rem;
}

table th {
  font-weight: 700;
  font-size: 11px;
  text-transform: uppercase;
}

tr.no-records-found {
  font-size: 0.75rem;
  font-weight: 500;
}

table.small td form {
  font-size: 115%;
}
table.small td form input[type=checkbox] {
  margin-left: -20px !important;
  margin-top: 4px !important;
}
table.small td form .input-group {
  width: 100%;
}

tr.inactive > td {
  opacity: 0.75 !important;
}

tbody tr.highlight td,
tbody tr.inactive.highlight td {
  background-color: #eff5f9 !important;
  background: #eff5f9 !important;
}

.table th {
  border-top: none;
}
.table th.image {
  width: 60px;
}

.bootstrap-table .disabled {
  color: rgba(0, 0, 0, 0.5) !important;
}

.bootstrap-table .search {
  width: 100%;
}

.bootstrap-table td.wide {
  width: 100%;
}

.bootstrap-table td.wide-80 {
  width: 80%;
}

.bootstrap-table .progress {
  height: 10px;
  width: 50px;
  margin: 0px auto;
  vertical-align: middle;
}

.bootstrap-table .tab-pane {
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.24);
  margin-bottom: 20px;
  border-radius: 7px;
}

td div.album-details {
  line-height: 1.1em;
}

.fixed-table-loading {
  z-index: 1;
  background-color: #f1f2f2;
  font-size: 0.75rem;
  padding: 7px 0 0px;
}

.progress-title {
  margin-left: 5px;
  font-size: 60%;
  color: #A7A7A7;
}

th:focus {
  outline: none;
}

td {
  vertical-align: middle !important;
}

.bootstrap-datetimepicker-widget {
  min-width: 260px !important;
  font-size: 14px !important;
}
.bootstrap-datetimepicker-widget.dropdown-menu {
  overflow: hidden !important;
  padding: 0 !important;
}
.bootstrap-datetimepicker-widget table {
  background-color: #ffffff;
}
.bootstrap-datetimepicker-widget table tbody {
  background-color: #ffffff !important;
}
.bootstrap-datetimepicker-widget table tr {
  background-color: #ffffff !important;
}
.bootstrap-datetimepicker-widget table td {
  background-color: #ffffff;
}
.bootstrap-datetimepicker-widget table td,
.bootstrap-datetimepicker-widget table th {
  border: none !important;
}

.nowrap {
  white-space: nowrap;
}

.bootstrap-datetimepicker-widget table td.active, .bootstrap-datetimepicker-widget table td.active:hover {
  background-color: #007bff !important;
}

.bootstrap-datetimepicker-widget table td.active {
  color: #ffffff !important;
}

.bootstrap-datetimepicker-widget table td.active.today:before {
  border-bottom-color: #fff !important;
}

.bootstrap-datetimepicker-widget table td.day {
  height: 15px !important;
  line-height: 15px !important;
  width: 15px !important;
}

.bootstrap-datetimepicker-widget table td.today {
  position: relative;
  text-shadow: none !important;
}

.timepicker-picker .table-condensed td {
  background-color: #ffffff !important;
}

.bootstrap-datetimepicker-widget table td.day:hover, .bootstrap-datetimepicker-widget table td.hour:hover, .bootstrap-datetimepicker-widget table td.minute:hover, .bootstrap-datetimepicker-widget table td.second:hover {
  background: #e9ecef !important;
}

.bootstrap-datetimepicker-widget table {
  border-bottom: 0px !important;
}

.fixed-table-container tbody td {
  border-left: 0px !important;
}

.bootstrap-table .table.table-no-bordered > tbody > tr > td, .bootstrap-table .table.table-no-bordered > thead > tr > th {
  border-right: 1px solid transparent !important;
}
.bootstrap-table .table.table-no-bordered > tbody > tr > td:last-child, .bootstrap-table .table.table-no-bordered > thead > tr > th:last-child {
  border-right: initial !important;
}

.multiselect-container .input-group {
  width: auto !important;
}

.bootstrap-table .table.table-no-bordered > tbody > tr > td {
  border-right: 0px solid transparent !important;
}

.bootstrap-table .table.table-no-bordered > thead > tr > th {
  border-right: 1px solid transparent !important;
}

.fixed-table-pagination .pagination a {
  padding: 3px 12px !important;
  color: #7d7d7d;
  font-size: 0.9rem;
}

.bootstrap-table .fixed-table-container .fixed-table-body .fixed-table-loading {
  background: #fafbfe !important;
  font-size: 0.75rem !important;
}

span.loading-text {
  font-size: 1rem !important;
}

.error-row {
  background-color: #fff3f4;
}

.bg-success-muted {
  background-color: rgba(100, 222, 92, 0.2);
}

td.image {
  opacity: 0;
  animation: fadeIn 0.5s ease-in forwards;
}

.adv-search {
  line-height: 1.2;
}
.adv-search span {
  display: block;
}
.adv-search-details {
  font-size: 11px;
  margin: 4px 0 0;
  color: #777777;
  line-height: 1.3;
}

.bootstrap-table .disabled .bs-checkbox {
  pointer-events: none;
  opacity: 0.2;
}

.bootstrap-table .disabled input[type=checkbox] {
  pointer-events: none; /* Prevents interaction with the checkbox */
  opacity: 0.2; /* Visually indicates it's disabled */
}

/******************************
*
*	Component: Tabs
*
******************************/
ul.nav-tabs {
  white-space: nowrap;
  border-bottom: 1px solid #E6E6E6 !important;
  margin: 15px 0 0 5px !important;
}
ul.nav-tabs > li {
  float: none;
  display: inline-block;
}
ul.nav-tabs > li > a {
  font-size: 0.8em;
  font-weight: 600;
  border: none !important;
  color: #52575b;
  background-color: transparent !important;
  margin: 10px 0px 0px 14px;
  padding: 0;
  white-space: nowrap;
  text-transform: uppercase;
}
ul.nav-tabs > li > a:hover {
  color: #333;
}
ul.nav-tabs > li > a.active {
  border-bottom: 3px solid #f05a24 !important;
  color: #f05a24 !important;
}

.tab-pane {
  padding: 20px;
}
.tab-pane > h3 {
  margin-top: 0;
  margin-bottom: 10px;
}

.tab-content.content .tab-pane {
  padding: 0px;
}

.nav-fill {
  border-radius: 6px;
  flex-wrap: nowrap;
}
.nav-fill .nav-item {
  border-radius: 0px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  border-right: 1px solid;
  outline: none;
}
.nav-fill .nav-item:last-child {
  border-right: none;
}
.nav-fill .nav-item.active:first-child {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.nav-fill .nav-item.active:last-child {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

/******************************
*
*	Component: Language Options
*
******************************/
.language-option {
  position: relative;
  display: none;
}
.language-option .badge {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 3;
}
.language-option .form-control {
  margin-bottom: 10px;
}
.language-option.show, .language-option.default {
  display: block;
}

pre {
  font-size: 75%;
  white-space: pre-wrap;
}

button.loading:before {
  content: "\f110";
}

button.playing:before {
  content: "\f04d";
}

button.stop:before {
  content: "\f04d";
}

/******************************
*
*	Component: Search
*
******************************/
.search {
  position: relative;
}

.tt-menu {
  border: 1px solid #CCC;
  width: 100%;
  max-height: 400px;
  overflow: scroll;
}

.twitter-typeahead {
  display: block !important;
}

.tt-dataset {
  text-align: left;
  background-color: #FFF;
  width: 100%;
}
.tt-dataset .badge-explicit {
  width: 10px;
  height: 10px;
}
.tt-dataset .badge-explicit:before {
  content: "e";
  font-family: "tunedicons";
}
.tt-dataset .badge-tag {
  padding: 0.4em 0.5em !important;
}
.tt-dataset div {
  font-size: 90%;
  padding: 5px;
  border-bottom: 1px solid #EEE;
  cursor: pointer;
}
.tt-dataset div:hover {
  background-color: #EFEFEF;
}
.tt-dataset div.tt-cursor {
  background-color: #EFEFEF;
}

.typeahead .stream-false {
  color: #EFEFEF;
}

/******************************
*
*	Component: Page Editing
*
******************************/
.display-type-row {
  border-bottom: 3px solid red;
}

td.nopadding {
  position: relative;
}

tr.selected.bg-success {
  background-color: #dff0d8 !important;
}
tr.selected.bg-success td {
  background-color: transparent;
}

tr.selected > td.bg-success {
  background-color: #dff0d8 !important;
}

td img.table-image {
  max-width: 60px;
  width: 60px;
  height: 60px;
  border-radius: 4px;
  opacity: 0;
  animation: fadeIn 0.5s ease-in forwards;
}

td img.table-image-video {
  max-width: 100px;
  border-radius: 4px;
  opacity: 0;
  animation: fadeIn 0.5s ease-in forwards;
}

td img.table-tile-image {
  max-width: 130px;
  border-radius: 2px;
  opacity: 0;
  animation: fadeIn 0.5s ease-in forwards;
}

td.small img.table-image {
  max-width: 30px;
  opacity: 0;
  animation: fadeIn 0.5s ease-in forwards;
}

td.nopadding img {
  margin: -8px;
}

div.nopadding {
  padding: 0px;
}

.table-image {
  position: relative;
  max-width: 40px;
  max-width: fit-content;
  opacity: 0;
  animation: fadeIn 0.5s ease-in forwards;
}
.table-image img {
  opacity: 0;
  animation: fadeIn 0.5s ease-in forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0; /* Start from fully transparent */
  }
  to {
    opacity: 1; /* Fade to fully opaque */
  }
}
.karaoke-badge {
  color: #000;
  background: #fff;
  border-radius: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 19px;
  z-index: 1;
  width: 20px;
  height: 20px;
  line-height: 0;
  text-align: center;
  margin: 4px;
}

.karaoke-badge-row {
  color: #000;
  background: #fff;
  border-radius: 100%;
  bottom: 0;
  right: 0;
  font-size: 19px;
  z-index: 1;
  width: 20px;
  height: 20px;
  line-height: 0;
  text-align: center;
  margin: 4px;
}

tr.gu-mirror {
  display: none;
}

tr.gu-transit {
  opacity: 1;
  color: #000;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}
tr.gu-transit td {
  background-color: rgba(109, 191, 137, 0.29) !important;
}

/******************************
*
*	Component: Content
*
******************************/
.gu-mirror {
  pointer-events: none;
}

#edit_bar_header {
  display: inline-block;
  margin: 0 0 5px 0;
  width: 100%;
}

.edit-bar {
  display: flex;
  flex-direction: column;
}

.content-title {
  font-size: 1.25rem;
  font-weight: 500;
  height: 25px;
  max-width: 60%;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2;
  white-space: nowrap;
  display: inline-block;
}

.editbar-btn {
  display: inline-block;
}
.editbar-btn a {
  font-size: 14px;
  padding: 0 0 0 20px;
  color: #848484 !important;
}

.recent-content {
  margin: 0 0 10px 0;
}

.content-button {
  background-repeat: no-repeat;
  background-size: contain;
  padding-top: 100px;
  width: 125px;
  margin-bottom: 10px;
  position: relative;
  font-size: 1em !important;
  border: 0;
}
.content-button span.fa {
  opacity: 0.5;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #bebebe;
  width: 100%;
  height: 95px;
  padding-top: 13px;
  font-size: 4.5em;
  pointer-events: none;
}
.content-button-title {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  letter-spacing: 0px;
  font-size: 14px;
  font-weight: 500;
}

#edit_bar {
  position: sticky;
  top: 0;
  z-index: 13;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  padding: 15px 20px;
  background-color: #FFF;
  border-radius: 4px;
  min-height: 100px;
}

.last-published {
  font-size: 11px;
  color: #888888;
  padding: 5px 0;
}

#publish_bar {
  border-radius: 0 0 4px 4px;
  bottom: 0;
  z-index: 11;
  padding: 10px 20px;
  background-color: rgba(250, 250, 250, 0.9);
  min-height: 55px;
  box-shadow: 3px 0 15px rgba(0, 0, 0, 0.1);
  position: sticky;
  bottom: 0px;
}

.btn-toolbar {
  width: 100%;
}
.btn-toolbar .btn {
  height: 35px;
  min-width: 39px;
  border-radius: 0.3rem;
}

.dropdown-header {
  padding: 0.3rem 1rem;
}
.dropdown-header .menu-icon {
  width: 17px !important;
  display: inline-block;
}
.dropdown-item {
  padding: 0.05rem 1rem;
}
.dropdown-item a {
  display: block;
}
.dropdown-menu {
  max-height: 300px;
  height: auto;
  overflow-y: auto;
  overflow-x: auto;
}
.dropdown-menu::-webkit-scrollbar {
  -webkit-appearance: none;
}
.dropdown-menu::-webkit-scrollbar:vertical {
  width: 11px;
}
.dropdown-menu::-webkit-scrollbar:horizontal {
  height: 11px;
}
.dropdown-menu::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white;
  background-color: rgba(0, 0, 0, 0.5);
}
.dropdown-menu::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 8px;
}

.content-row {
  padding: 1px;
  border-radius: 6px;
  border: 1px solid #FFF;
  position: relative;
  list-style: none;
  text-transform: initial;
}
.content-row.selected {
  border: 2px dotted rgba(255, 213, 175, 0.7607843137);
  background: rgba(255, 221, 127, 0.05) !important;
}
.content-row.selected .group-name {
  background: rgba(255, 221, 127, 0.05) !important;
}
.content-row .form-group {
  margin-bottom: 8px;
  background: #f4f4f4;
  border-radius: 5px;
  padding: 15px;
}
.content-row .option {
  padding: 15px;
  border: 1px solid #cecece;
  border-radius: 5px;
}
.content-row .content-item.display-shelf, .content-row .content-item.display-grid, .content-row .content-item.display-list {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 150px;
  text-align: center;
  padding: 15px 20px;
  border-radius: 6px;
  border: 2px solid #D4D4D4;
  cursor: default;
  user-select: none;
}
.content-row .content-item.display-group {
  padding: 20px 5px 5px;
  background-color: transparent;
  text-align: left;
  border-radius: 6px;
  border: 3px dashed #f1f1f1;
  position: relative;
  margin: 5px 0 0;
  min-height: 50px;
}
.content-row .content-item.display-group span.group-name {
  position: absolute;
  padding: 2px 8px;
  background-color: #ffffff;
  top: -9px;
  left: 5px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 10px;
  pointer-events: none;
}
.content-row .content-item.display-group::before {
  text-transform: initial;
  text-align: center;
  width: 100%;
  top: 40%;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  color: #dedede;
  font-size: 18px;
}
.content-row .content-item.display-group span.group-priority {
  position: absolute;
  padding: 2px 8px;
  background-color: #ffffff;
  top: -9px;
  right: 5px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 10px;
  pointer-events: none;
}
.content-row .gu-transit {
  pointer-events: none;
}
.content-row .content-controls {
  transition: all 0.1s ease;
  visibility: hidden;
  position: absolute;
  z-index: 10;
  top: 5px;
}
.content-row .content-controls .btn.fa {
  padding: 5px;
  min-width: 29px;
}
.content-row .content-controls.left {
  left: 5px;
}
.content-row .content-controls.center {
  left: 50px;
}
.content-row .content-controls.right {
  right: 5px;
}
.content-row .content-controls button {
  font-size: 13px;
  padding: 5px;
}
.content-row:hover {
  border: 1px solid #CCC;
}
.content-row:hover .content-controls {
  visibility: visible;
}
.content-row p[contenteditable=true] {
  outline: none;
  text-indent: 10px;
  border-radius: 3px;
}
.content-row.gu-transit {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
}
.content-row img {
  pointer-events: none;
}
.content-row .content-tip-route {
  color: #FFF;
  background-color: rgba(0, 0, 0, 0.4);
  white-space: nowrap;
  position: absolute;
  top: 10px;
  right: 40px;
  padding: 0 10px;
  font-weight: 100;
}
.content-row .item_form {
  z-index: 12;
  display: none;
  position: absolute;
  top: 0px;
  left: 10px;
  right: 10px;
  text-align: left;
  box-shadow: 0 0 0px 2000px rgba(0, 0, 0, 0.4);
}
.content-row.content-p {
  transition: all 0.2s ease;
}
.content-row.content-p:hover {
  padding: 38px 5px 5px;
}
.content-row.content-p ul {
  margin: 0 0 0 50px;
}
.content-row.content-p p {
  margin-bottom: 0px;
  min-width: 100%;
  min-height: 20px;
  cursor: text;
  display: inline-block;
}
.content-row.content-p p:empty:after {
  content: "Section (no translation)";
  color: red;
}
.content-row.content-h1 {
  transition: all 0.2s ease;
  padding: 0 2px;
}
.content-row.content-h1:hover {
  padding: 30px 5px 5px;
}
.content-row.content-h1 p {
  font-size: 1.6em;
  font-weight: 400;
  margin-bottom: 0px;
  width: 100%;
  min-height: 35px;
  cursor: text;
  display: inline-block;
  line-height: initial;
}
.content-row.content-h1 p:empty:after {
  content: "Heading (no translation)";
  color: red;
}
.content-row.content-p button.fa-pencil {
  display: none;
}
.content-row.content-ad_banner .content-item {
  position: relative;
  height: 95px;
  border: 2px solid #D4D4D4;
  background-color: #ffffff;
  text-align: center;
  padding-top: 10px;
  border-radius: 6px;
  font-weight: 400;
  font-size: 14px;
}
.content-row.content-ad_banner .content-item img {
  width: 190px;
}
.content-row.content-ad_banner .content-item .display-always {
  background: #747474;
  border-radius: 3px;
  text-transform: uppercase;
  color: #ffffff;
  font-size: 11px;
  padding: 1px 5px;
  position: absolute;
  top: 5px;
  right: 5px;
}
.content-row.content-spacing .content-item {
  height: 20px;
  background-color: transparent;
  text-align: center;
  border-radius: 6px;
  border: 1.5px dashed #e3e3e3;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
}
.content-row.content-separator .content-item {
  position: relative;
  height: 22px;
  background-color: transparent;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
}
.content-row.content-separator .content-item:before {
  content: "Separator Line";
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  left: 0;
  margin: auto;
  padding: 0 10px;
  width: 130px;
  background-color: #ffffff;
}
.content-row.content-separator .content-item:after {
  content: "";
  position: absolute;
  height: 1px;
  width: 100%;
  background: #d4d4d4;
  top: 30px;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.shelf {
  width: 100%;
  text-align: left;
}
.shelf-heading {
  font-size: 20px;
  font-weight: 400;
}
.shelf-display, .shelf-info, .shelf-playlist {
  margin: 5px 0;
  line-height: 1.3;
  font-size: 14px;
  font-weight: 400;
}
.shelf-display span, .shelf-info span, .shelf-playlist span {
  font-weight: 300;
}

.card-container {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0 0 20px 0;
  margin: 0 0 20px 0;
}
.card-container:last-of-type {
  border-bottom: 0px;
  padding: 0;
  margin: 0;
}

#bpm-range, #duration_range {
  margin: 0 10px;
}

#content_settings, #content_history {
  margin-top: -37px;
  transition: visibility 0s, height 0.3s;
}
#content_settings .card-body, #content_history .card-body {
  padding-bottom: 0;
}
#content_settings .card-body p, #content_history .card-body p {
  margin-bottom: 0;
}
#content_settings .card, #content_history .card {
  text-align: left;
  max-height: 400px;
  height: auto;
  overflow: scroll;
  margin: 0 0 10px 0;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.2);
}
#content_settings .card::-webkit-scrollbar, #content_history .card::-webkit-scrollbar {
  -webkit-appearance: none;
}
#content_settings .card::-webkit-scrollbar:vertical, #content_history .card::-webkit-scrollbar:vertical {
  width: 11px;
}
#content_settings .card::-webkit-scrollbar:horizontal, #content_history .card::-webkit-scrollbar:horizontal {
  height: 11px;
}
#content_settings .card::-webkit-scrollbar-thumb, #content_history .card::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white;
  background-color: rgba(0, 0, 0, 0.5);
}
#content_settings .card::-webkit-scrollbar-track, #content_history .card::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 8px;
}
#content_settings .card-container, #content_history .card-container {
  display: block;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  padding: 0 0 20px 0;
  margin: 0 0 20px 0;
}
#content_settings .card-container:last-of-type, #content_history .card-container:last-of-type {
  border-bottom: 0px;
  padding: 0;
  margin: 0;
}
#content_settings .form-group, #content_history .form-group {
  text-align: left;
}

.verified-content {
  margin: 0 0 10px;
}

.tokenfield {
  cursor: default !important;
  pointer-events: none !important;
}
.tokenfield .token-input {
  cursor: default !important;
}
.tokenfield.focus {
  display: none !important;
}
.tokenfield.form-control {
  border: 0px !important;
  padding: 0;
}
.tokenfield .token {
  position: relative;
  border-radius: 25px;
  margin: 2px 5px 2px 0;
  padding: 0 5px 0;
  height: 27px;
}
.tokenfield .token .close {
  pointer-events: all !important;
  font-size: 1.3em !important;
  top: 0px !important;
  line-height: 25px;
}
.tokenfield .token .token-label {
  padding: 3px 4px !important;
  font-size: 12px;
}

.form-group {
  position: relative;
}

.shelf-product {
  line-height: 0.9rem;
  padding: 5px 0 5px 37px;
}
.shelf-product small {
  color: #747474;
  font-size: 9.5px;
}

.content-emptystate {
  color: #6d757d;
  margin: 130px 0;
}
.content-emptystate .empty-state-icon {
  font-size: 35px;
  margin: 0 0 11px 0;
}
.content-emptystate .empty-state-title {
  font-weight: 500;
  font-size: 1.25rem;
}

.play-button {
  z-index: 1;
  position: relative;
  float: left;
}

.time {
  text-align: right;
  font-size: 13px;
  font-weight: 400;
  position: absolute;
  z-index: 0;
  right: 11px;
  top: 15px;
  width: 100px;
}

.audio-progress {
  height: 100%;
  margin: 0 80px 0 50px;
  left: 0;
  right: 0;
  top: -2px;
  position: relative;
}

.audio-player {
  background: #ffffff;
  position: relative;
  height: 54px;
  border: 1px solid #eaeaea;
  margin-bottom: 30px;
  padding: 10px;
  position: relative;
  border-radius: 5px;
  box-shadow: 1px 1px 12px #d9d9d9;
}
.audio-player input[type=range] {
  background-color: #a3a3a3;
  height: 0;
  -webkit-appearance: none;
  width: 100%;
  border: 0;
}
.audio-player input[type=range]:focus {
  outline: none;
}
.audio-player input[type=range]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  width: 100%;
  height: 7px;
  cursor: pointer;
  border-radius: 50px;
  border: 0px solid #FFFFFF;
  background-color: #a3a3a3;
}
.audio-player input[type=range]::-webkit-slider-thumb {
  box-shadow: 1px 1px 3px #A3A3A3;
  border: 1px solid #ADADAD;
  height: 15px;
  width: 15px;
  border-radius: 50px;
  background: #FFFFFF;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -5px;
  transition: all 0.2s ease-in-out;
}
.audio-player input[type=range]:hover::-webkit-slider-thumb {
  transform: scale(1.5);
}
.audio-player input[type=range]:focus::-webkit-slider-runnable-track {
  background: #333333;
}
.audio-player input[type=range]::-moz-range-track {
  width: 100%;
  height: 10px;
  cursor: pointer;
  box-shadow: 0px 0px 0px #333333;
  background: #333333;
  border-radius: 50px;
  border: 0px solid #FFFFFF;
}
.audio-player input[type=range]::-moz-range-thumb {
  box-shadow: 1px 1px 3px #A3A3A3;
  border: 1px solid #ADADAD;
  height: 18px;
  width: 18px;
  border-radius: 50px;
  background: #FFFFFF;
  cursor: pointer;
}
.audio-player input[type=range]::-ms-track {
  width: 100%;
  height: 10px;
  cursor: pointer;
  border-color: transparent;
  color: transparent;
}
.audio-player input[type=range]::-ms-fill-lower {
  background: #333333;
  border-radius: 100px;
}
.audio-player input[type=range]::-moz-range-progress {
  background: #333333;
  border-radius: 100px;
}
.audio-player input[type=range]::-moz-range-track {
  background-color: #A3A3A3;
  border-radius: 100px;
}
.audio-player input[type=range]::-ms-fill-upper {
  background: #A3A3A3;
  border-radius: 100px;
}
.audio-player input[type=range]::-ms-thumb {
  margin-top: 1px;
  box-shadow: 1px 1px 3px #A3A3A3;
  border: 1px solid #ADADAD;
  height: 18px;
  width: 18px;
  border-radius: 50px;
  background: #FFFFFF;
  cursor: pointer;
}
.audio-player input[type=range]:focus::-ms-fill-lower {
  background: #333333;
}
.audio-player input[type=range]:focus::-ms-fill-upper {
  background: #A3A3A3;
}

.arrow-icon {
  transition: transform 0.3s ease;
}

.rotate-180 {
  transform: rotate(180deg);
}

.setting-block {
  margin: 15px 0;
}
.setting-block h6 {
  margin-bottom: 0;
}
.setting-block p {
  color: #A8A8A8;
  font-size: 12px;
  margin-bottom: 5px !important;
}
.setting-block.bordered {
  border-radius: 5px;
  border: 1px solid #DADADA;
  padding: 20px;
  color: #848484;
}
.setting-block.filled {
  border-radius: 5px;
  background: #F2F2F2;
  padding: 20px;
}
.setting-block .form-check {
  margin: 10px 0;
}

.content-value {
  padding: 10px;
  pointer-events: none;
}

#song-count {
  font-weight: 500;
  width: 200px;
}

.bar-container {
  width: 100%;
  background-color: #7c7c7c;
  border-radius: 4px;
  overflow: hidden;
  margin-top: 10px;
  position: relative;
}

.bar {
  height: 20px;
  background-color: #4caf50;
  text-align: center;
  color: white;
  line-height: 20px;
  width: 0;
  transition: width 0.5s ease;
}

.bar-label {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  font-size: 11px;
  text-align: center;
  line-height: 20px;
  color: #ffffff;
}

/******************************
*
*	Component: Preview
*
******************************/
#layout_preview {
  position: relative;
  width: 300px;
  margin: 0 auto;
  overflow: auto;
  clear: both;
  display: table;
  box-shadow: 0 0 20px #CCC;
}
#layout_preview.ipad {
  width: 600px;
  zoom: 0.6;
}
#layout_preview span.page_item {
  float: left;
  display: table-cell;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: bottom;
  background-color: #EFEFEF;
}
#layout_preview span.page_item img {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  border: 0px;
}
#layout_preview span.page_item.type_1 {
  height: 300px;
  width: 300px;
}
#layout_preview span.page_item.type_2 {
  height: 150px;
  width: 300px;
}
#layout_preview span.page_item.type_3 {
  height: 150px;
  width: 150px;
}
#layout_preview span.page_item p {
  margin: 0px;
  color: #FFF;
  position: absolute;
  text-shadow: 0 0 10px #000;
  background-color: rgba(0, 0, 0, 0.2);
  bottom: 0px;
  padding: 5px;
  width: 100%;
  display: inline-block;
}

#carousel_preview {
  overflow: hidden;
  overflow-x: scroll;
  white-space: nowrap;
  padding: 0 0 10px 0;
}
#carousel_preview ul {
  margin: 0px;
  padding: 0px;
  width: auto;
}
#carousel_preview ul li {
  display: inline-block;
  height: 170px;
  width: 170px;
  border-radius: 6px;
  border: 1px solid #EFEFEF;
  list-style: none;
  margin-right: 5px;
  background-size: cover;
  position: relative;
}
#carousel_preview ul li a.btn {
  position: absolute;
  opacity: 0.7;
  zoom: 70%;
}
#carousel_preview ul li span {
  background-color: rgba(0, 0, 0, 0.8);
  color: #FFF;
  padding: 2px 8px;
  font-weight: lighter;
  position: absolute;
  bottom: 0px;
  border-radius: 0 0 0 6px;
  font-size: 0.8em;
}
#carousel_preview ul li span.link {
  right: 0px;
  border-radius: 0 0 6px 0;
  font-size: 60%;
  max-width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
#carousel_preview ul .tile_advertisement {
  background-color: #ffffff;
}
#carousel_preview ul .tile_advertisement img {
  width: 100%;
  display: block;
}
#carousel_preview.wide li {
  width: 260px;
  height: 160px;
}
#carousel_preview.fullwidth li {
  width: 220px;
  height: 220px;
}
#carousel_preview.extended li {
  width: 236px;
  height: 315px;
}

.dragfile .dropzone {
  outline: 4px dashed #FFF;
  outline-offset: -10px;
  text-align: center;
  padding: 40px;
  margin-left: 20px;
}
.dragfile .dropzone .percentage {
  display: none;
}
.dragfile .dropzone span.fa, .dragfile .dropzone .percentage {
  font-size: 7em;
  padding-bottom: 10px;
}
.dragfile .dropzone .fa.small {
  font-size: 3em;
}
.dragfile .dropzone .percentage.small {
  font-size: 5em;
}
.dragfile label {
  font-weight: bold;
  cursor: pointer;
}
.dragfile.is-dragover .dropzone {
  background-color: #FFF;
}
.dragfile input {
  display: none;
}
.dragfile.is-dropped .percentage {
  display: inline-block;
}
.dragfile.is-dropped .instructions {
  display: none;
}

#CarouselPreview {
  border: 1px solid #CCC;
  background: #ebebeb;
  overflow: hidden;
  position: relative;
  border-radius: 3px;
}
#CarouselPreview.wide {
  width: 350px;
  height: 206px;
}
#CarouselPreview.fullwidth {
  width: 350px;
  height: 350px;
}
#CarouselPreview input {
  position: absolute;
  bottom: 5px;
  background-color: rgba(0, 0, 0, 0.4);
  border: none;
  outline: none;
  color: #FFF;
  left: 5px;
  right: 5px;
  width: 367px;
}
#CarouselPreview input#cp_Title {
  bottom: 45px;
}
#CarouselPreview #cp_Image {
  width: 100%;
}

/******************************
*
*	Component: Stats
*
******************************/
.dashboard {
  margin: 20px 20px;
}
.dashboard-item {
  margin: 0 0 15px 0;
}

.stat-block {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 6px rgba(167, 167, 167, 0.32);
  margin: 0 0 15px;
}
.stat-block-content {
  padding: 15px;
}
.stat-block .icon:before {
  font-size: 20px;
}

.statscounter {
  line-height: 1.5;
  font-size: 14px;
  font-weight: 400;
}
.statscounter .stat-icon {
  float: right !important;
  color: #909090;
}
.statscounter .stat-title {
  color: #52575b;
  text-transform: uppercase;
  margin: 2px 0 0 0;
  font-size: 0.65rem;
  font-weight: 500;
}
.statscounter .stat-count {
  font-size: 1.8em;
  font-weight: 500;
}
.statscounter .stat-link {
  display: block;
  color: #909090;
  font-size: 12px;
  font-weight: 400;
}
.statscounter .stat-link .arrow {
  font: normal normal normal 14px/1 FontAwesome;
  float: right;
  transform: rotate(90deg);
  padding: 4px 0;
  color: #909090;
  font-size: 10px;
}
.statscounter .stat-link .arrow:before {
  content: "\f077";
}

/******************************
*
*	Component: LyricIQ
*
******************************/
.sentiment-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
  cursor: pointer;
}

.sentiment-badge {
  border-radius: 10px;
  margin-right: 5px;
  text-align: center;
  padding: 3px 10px;
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
}

.red {
  background-color: #d72f2f;
}

.light-red {
  background-color: #ff6666;
}

.yellow {
  background-color: #eba61b;
}

.grey {
  background-color: #a9a9a9;
}

.cyan {
  background-color: #28861f;
}

.green {
  background-color: #26b526;
}

.light-green {
  background-color: #50dc50;
}

.sentiment-dot:hover::after {
  position: absolute;
  top: -25px;
  left: 0;
  padding: 5px;
  background-color: black;
  color: white;
  border-radius: 5px;
  white-space: nowrap;
}

.sentiment-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3px;
  background-color: #bcbcbc;
  position: relative;
  margin: 30px 40px 60px 0;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #d1d1d1;
  border: 1px solid #ffffff;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.dot.active {
  width: 16px;
  height: 16px;
}
.dot-label {
  position: absolute;
  line-height: 12px;
  top: 18px;
  left: 0px;
  font-weight: 400;
  font-size: 10px;
  color: #969696;
}
.dot .label-active {
  color: #636363;
  font-weight: 500;
  font-size: 12px;
}

[data-sentiment=extremely-negative] {
  right: 0%;
  left: auto;
  background-color: #d72f2f;
}

[data-sentiment=negative] {
  right: 16.67%;
  left: auto;
  background-color: #ff6666;
}

[data-sentiment=somewhat-negative] {
  right: 33.33%;
  left: auto;
  background-color: #eba61b;
}

[data-sentiment=neutral] {
  right: 50%;
  left: auto;
  background-color: #444444;
}

[data-sentiment=somewhat-positive] {
  right: 66.67%;
  left: auto;
  background-color: #28861f;
}

[data-sentiment=positive] {
  right: 83.33%;
  left: auto;
  background-color: #26b526;
}

[data-sentiment=extremely-positive] {
  left: 0%;
  background-color: #50dc50;
}

.content-filter-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 20px;
  flex-wrap: wrap;
  margin: 0 0 20px 0;
  overflow: hidden;
}

.content-filter {
  flex: 1 0 20%;
  text-align: center;
  background-color: #e7e7e7;
  padding: 2px;
  border: 1px solid #ffffff;
  color: #969696;
  font-size: 10px;
  font-weight: 500;
}

.content-filter.highlight {
  background-color: #d72f2f;
  color: #ffffff;
}

.content-filter:first-child::before,
.content-filter:last-child::after {
  content: "";
  display: block;
  width: 10px;
  height: 100%;
  background-color: #f0f0f0;
  border-radius: 50%;
}

.content-filter:first-child::before {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.content-filter:last-child::after {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.emotion-name {
  margin: 10px 0 0 0;
  font-weight: 500;
  font-size: 12px;
}

.emotion-bar {
  height: 20px;
  border-radius: 5px;
  margin-top: 1px;
  position: relative;
  overflow: hidden;
}
.emotion-bar span.emotion-percentage {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #333333;
  font-size: 12px;
  font-weight: 600;
}

.emotion-filter-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 20px;
  flex-wrap: wrap;
  overflow: hidden;
  margin: 0 0 10px;
}

.emotion-filter {
  flex: 1 0 20%;
  text-align: center;
  background-color: #e7e7e7;
  padding: 2px;
  border: 1px solid #ffffff;
  color: #969696;
  font-size: 10px;
  font-weight: 500;
}

.emotion-filter.highlight {
  background-color: #eba61b;
  color: #ffffff;
}

.emotion-filter:first-child::before,
.emotion-filter:last-child::after {
  content: "";
  display: block;
  width: 10px;
  height: 100%;
  background-color: #f0f0f0;
  border-radius: 50%;
}

.emotion-filter:first-child::before {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.emotion-filter:last-child::after {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.content-name {
  font-weight: 500;
  font-size: 12px;
}

/******************************
*
*	Components: Pagination
*
******************************/
.pagination-detail {
  width: 100%;
  color: #909090;
  font-size: 0.7rem;
  font-weight: 400;
}
.pagination .page-item.active .page-link {
  background: #6c757d;
  border-color: #6c757d;
}

/******************************
*
*	Component: Badges
*
******************************/
.badge {
  display: inline-block;
  padding: 0.4em 0.5em;
  font-size: 0.5rem;
  vertical-align: middle;
}
.badge-system {
  color: #ffffff;
  font-size: 8px !important;
  background-color: #888888;
  text-transform: uppercase;
  font-size: 8px;
  font-weight: 500 !important;
}
.badge-system:hover {
  background-color: #888888;
}
.badge-merchant {
  border: 1px solid #d1d1d1;
  padding: 4px;
  font-size: 11px;
  font-weight: 500;
}
.badge-success {
  color: #ffffff;
  background-color: #28a745;
}
.badge-info {
  color: #ffffff;
  background-color: #15bed8;
}
.badge-danger {
  color: #ffffff;
  background-color: #dc3545;
}
.badge-warning {
  color: #ffffff;
  background-color: #ffc107;
}
.badge-unpublished {
  color: #ffffff;
  background-color: #b1b1b1;
}
.badge-inactive {
  color: #ffffff;
  background-color: #b1b1b1;
}
.badge-active {
  color: #ffffff;
  background-color: #28a745;
}
.badge-onhold {
  color: #ffffff;
  background-color: #ffc107;
}
.badge-suspended {
  color: #ffffff;
  background-color: #dc3545;
}
.badge-explicit {
  color: #dc3545;
  display: inline-block;
}
.badge-explicit:before {
  content: "e";
  font-family: "tunedicons";
}
.badge-tag {
  color: #ffffff;
  font-size: 8px !important;
  background-color: #888888;
  text-transform: uppercase;
  margin: 0 1px;
  font-size: 8px;
  font-weight: 500 !important;
}
.badge-tag:hover {
  background-color: #888888;
}
.badge-nft {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-image: url("/images/nft_badge.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.tag-badge, .token-badge-other:before, .token-badge-user:before, .token-badge-country:before, .token-badge-genre:before, .token-badge-general:before, .token-badge-system:before {
  border-radius: 3px;
  margin: 3px 0 0 5px;
  font-weight: 500 !important;
  color: #ffffff;
  padding: 0.1em 0.3em !important;
  font-size: 10px !important;
  line-height: 14px;
  background-color: #888888;
  text-transform: uppercase;
}

.token-badge-system:before {
  content: "System Genre";
}
.token-badge-general:before {
  content: "General";
}
.token-badge-genre:before {
  content: "Genre";
}
.token-badge-country:before {
  border-radius: 3px;
  content: "Country";
}
.token-badge-user:before {
  content: "User";
}
.token-badge-other:before {
  content: "Other";
}

@font-face {
  font-family: "tunedicons";
  src: url("../../fonts/tunedicons.eot");
  src: url("../../fonts/tunedicons.ttf") format("truetype"), url("../../fonts/tunedicons.woff") format("woff"), url("../../fonts/tunedicons.svg#tunedicons") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
.icon, .artist-input:after {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "tunedicons" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-nav_airplay:before {
  content: "ˆ";
}

.icon-close-round:before {
  content: "∑";
}

.icon-downloading:before {
  content: "–";
}

.icon-info:before {
  content: "÷";
}

.icon-info-error:before {
  content: "÷";
  color: #dc3545;
  font-size: 20px;
}

.icon-follow:before {
  content: "}";
}

.icon-icon-lyrics:before {
  content: ":";
}

.icon-author:before {
  content: "_";
}

.icon-video-round:before {
  content: "F";
}

.icon-note-round:before {
  content: "S";
}

.icon-nav_search_round:before {
  content: "W";
}

.icon-queue-play-next:before {
  content: "$";
}

.icon-eye-off:before {
  content: "~";
}

.icon-volume-mute:before {
  content: "|";
}

.icon-shuffle-active:before {
  content: "?";
}

.icon-loop-single-active:before {
  content: "ł";
}

.icon-loop-active:before {
  content: "*";
}

.icon-arrow-prefix:before {
  content: "V";
  color: #555;
}

.icon-unticked:before {
  content: "C";
}

.icon-ticked:before {
  content: "D";
}

.icon-ticked-success:before {
  content: "D";
  color: #28a745;
  font-size: 18px;
}

.icon-filter:before {
  content: '"';
}

.icon-sort:before {
  content: "'";
}

.icon-icon-addmusic:before {
  content: ")";
}

.icon-icon-musicnote:before {
  content: "0";
}

.icon-icon-replayvideo:before {
  content: "2";
}

.icon-icon-video-outlined:before {
  content: "1";
}

.icon-icon-video:before {
  content: "t";
}

.icon-queue:before {
  content: "•";
}

.icon-music-album:before {
  content: "¢";
}

.icon-app_playlist:before {
  content: "`";
}

.icon-user_playlist:before {
  content: "¡";
}

.icon-home:before {
  content: "≠";
}

.icon-add_track_toggle_active:before {
  content: "≥";
}

.icon-add_track_toggle:before {
  content: "≤";
}

.icon-add_playlist:before {
  content: "∂";
}

.icon-add_song:before {
  content: "∞";
}

.icon-more_vertical:before {
  content: "“";
}

.icon-female:before {
  content: "♀";
}

.icon-email:before {
  content: "ë";
}

.icon-addtoqueue:before {
  content: "a";
}

.icon-artist:before {
  content: "å";
}

.icon-clock:before {
  content: "@";
}

.icon-calendar:before {
  content: "ć";
}

.icon-star_filled:before {
  content: "☻";
}

.icon-star_empty:before {
  content: "☺";
}

.icon-download_ok:before {
  content: "√";
}

.icon-download:before {
  content: "↓";
}

.icon-download_error:before {
  content: "d";
}

.icon-megaphone:before {
  content: "(";
}

.icon-camera:before {
  content: ";";
}

.icon-pictures:before {
  content: "Q";
}

.icon-trash:before {
  content: "Y";
}

.icon-redo:before {
  content: "J";
}

.icon-cog:before {
  content: "☼";
}

.icon-location:before {
  content: "▼";
}

.icon-profile:before {
  content: "O";
}

.icon-user-male:before {
  content: "♂";
}

.icon-key:before {
  content: "K";
}

.icon-padlock:before {
  content: "P";
}

.icon-unlock:before {
  content: "L";
}

.icon-share:before {
  content: "{";
}

.icon-warning:before {
  content: "i";
}

.icon-loop:before {
  content: "l";
}

.icon-shuffle:before {
  content: "4";
}

.icon-song:before {
  content: "y";
}

.icon-volume:before {
  content: "-";
}

.icon-album:before {
  content: "o";
}

.icon-playlist:before {
  content: "p";
}

.icon-radio:before {
  content: "r";
}

.icon-player-settings:before {
  content: "%";
}

.icon-up:before {
  content: ".";
}

.icon-down:before {
  content: ",";
}

.icon-right:before {
  content: "]";
}

.icon-left:before {
  content: "[";
}

.icon-cross:before {
  content: "X";
}

.icon-more:before {
  content: "…";
}

.icon-plus:before {
  content: "+";
}

.icon-search:before {
  content: "&";
}

.icon-tick:before {
  content: "T";
}

.icon-drag-vertical:before {
  content: "↕";
}

.icon-grid-large:before {
  content: "G";
}

.icon-grid-small:before {
  content: "g";
}

.icon-list:before {
  content: "#";
}

.icon-thumb-down:before {
  content: "ū";
}

.icon-thumb-up:before {
  content: "ō";
}

.icon-voucher:before {
  content: "ö";
}

.icon-eye:before {
  content: "I";
}

.icon-microphone:before {
  content: "!";
}

.icon-chromecast:before {
  content: "c";
}

.icon-explicit:before {
  content: "e";
}

.icon-followers:before {
  content: "f";
}

.icon-next:before {
  content: ">";
}

.icon-pause:before {
  content: "=";
}

.icon-pencil:before {
  content: "/";
}

.icon-play:before {
  content: "^";
}

.icon-previous:before {
  content: "<";
}

.icon-karaoke:before {
  content: "⬈";
}

.icon-audiobooks:before {
  content: "⋂";
}

#chat-container {
  margin: 0;
}

.message {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.user {
  background-color: rgb(243, 243, 243);
  text-align: left;
  font-weight: 500;
}

.bot {
  border: 1px solid #e7e7e7;
  text-align: left;
  min-height: 190px;
}

.response-table {
  margin-bottom: auto !important;
  width: 100%;
}
.response-table td, .response-table th {
  padding: initial !important;
}
.response-table .table-image {
  margin: 2px;
}

.checkbox-cell {
  text-align: center;
}

.select-all-checkbox {
  margin: 0 10px;
}