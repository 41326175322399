/******************************
*
*	Breakpoints
*
******************************/

@media (max-width: 64em) {
  #page_item, #page_section {
    width: 100%!important;
  }
}

@include breakpoint(mobileonly) {
  #menu-toggle {
    width: 45px;
    position: fixed;
  }

  #edit_bar {
    top: 0!important;
  }

  #page_section {
    height: 100vh;
  }

  #page_menu {
    top: 0;
    padding-top: 0;
  }

  .logo-container {
    display: none!important;
  }

  .login {
    &-logo {
      transform: inherit;
      top: 39px;
      left: 30px;
      width: 130px;
    }
    &-banner {
      min-height: 120px;
    }
  }

  .store {
    margin-left: 40px;
    .storename {
      font-size: 16px!important;
    }
  }

  #page_header {
    .storename {
      margin: 0 0 0 10px!important;
    }
    .header-nav {
      gap: 5px!important;
    }
    .profile {
      &-details {
        font-size: .75em!important;
        small {
          font-size: 8px!important;
        }
      }
    }
  }

  .header-nav-button {
    .chevron {
      right: 10px!important;
    }
  }
  
}

@include breakpoint(phablet) {
  #page_item {
    padding: 40px;
  }

  .logo {
    margin: 0 0 0 30px;
  }

  button.item-close,
  button.item-reload {
    right: 5px;
  }

  #page_header .spinner {
    display: none;
  }
  
}

@include breakpoint(tablet) {
  #page_item {
    padding: 40px;
  }

  button.item-close,
  button.item-reload {
    right: 10px;
  }

}

@include breakpoint(laptop) {
  #page_menu {
    position: fixed;
  }

  #page_header .spinner {
    display: none;
  }

  #page_section {
    position: fixed;
    overflow-y: scroll;
    width: 50%;
    padding-left: 0px;
    left: 0;
    height: 100%;
  }

  #page_item {
    height: calc(100vh - 48px)!important;
    display: inline-block;
    opacity: 1;
    position: fixed;
    top: 47px;
    left: auto;
    right: 0;
    overflow-y: auto;
    width: 50%;
    padding: 0;
  }

  .item-content {
    overflow: visible;
    height: auto;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  button {
    &.item-close {
      display: none;
    }

    &.item-reload {
      right: 0.5%;
      height: 40px;
      width: 40px;
    }
  }

  #gutter {
    display: block;
  }

  #framework.wideMode {
    #page_section {
      width: 15%;
      overflow: hidden;
      white-space: nowrap;
      opacity: 0.2;
    }

    .item-resize {
      background-color: $brand-primary;
      color: #FFF;
    }
  }
}

@include breakpoint(desktop) {
  #page_menu {
    display: inline-block;
    opacity: 1;
    left: 0;
  }

  .logo {
    margin: 0;
  }

  #page_header {
    left: 0px;
  }

  #menu-toggle {
    display: none;
  }

  #page_section {
    padding-left: $menu_width;
    top: $header_height;
  }

  #page_header .spinner {
    display: block;
  }
  
}
