/******************************
*
*	Component: Alerts
*
******************************/

body > .alert {
  border: 0;
  &.alert-success {
    background-color: $alert-success !important;
    color: #FFF !important;
  }
  &.alert-info {
    background-color: $alert-info !important;
    color: #FFF !important;
  }
  &.alert-danger {
    background-color: $alert-danger !important;
    color: #FFF !important;
  }
  &.alert-warning {
    background-color: $alert-warning !important;
    color: #333 !important;
  }
}


.alert {
  border: 0;
  font-size: .85rem;
  font-weight: 400;
  line-height: 1.4;
  padding: .55rem 0.85rem!important;
  &-info {
    color: #52575b!important;
    background-color: #ededed!important;
    border: 0px;
    &.tips {
      color: #52575b!important;
      background-color: #d3eeff!important;
      border-color: #d3eeff!important;
      font-size: .85rem;
      font-weight: 400;
      line-height: 1.4;
      padding: .55rem 0.85rem!important;
    }
  }
  &-unsaved {
    background: #F2BC58;
    color: #3f3f3f;
    padding: 0.25rem 0.85rem!important;
    margin: 10px 15px;
    p {
      margin: 0 30px;
      font-size: 12px;
      text-align: center;
    }
    .close {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 10px;
      margin: auto;
      font-size: 16px!important;
    }
  }
}

.alert-overdue {
  background: $alert-warning;
  color: #333333;
  padding: 10px 15px;
  font-size: 12px;
  border-radius: 5px;
  margin: 0 0 20px 0;
  transition: background-color 0.5s ease;
  span {
    font-size: 13px;
    display: block;
    font-weight: 500;
  }
}

.alert-suspended {
  background: $alert-danger;
  color: #ffffff;
  padding: 10px 15px;
  font-size: 12px;
  border-radius: 5px;
  margin: 0 0 20px 0;
  transition: background-color 0.5s ease;
  span {
    font-size: 13px;
    display: block;
    font-weight: 500;
  }
}

.alert-inactive {
  background: #b1b1b1;
  color: #ffffff;
  padding: 10px 15px;
  font-size: 12px;
  border-radius: 5px;
  margin: 0 0 20px 0;
  transition: background-color 0.5s ease;
  span {
    font-size: 13px;
    display: block;
    font-weight: 500;
  }
}
