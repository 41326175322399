/******************************
*
*	Component: Badges
*
******************************/

.badge {
  display: inline-block;
  padding: .4em .5em;
  font-size: .5rem;
  vertical-align: middle;
  &-system {
    color: #ffffff;
    font-size: 8px!important;
    background-color: #888888;
    text-transform: uppercase;
    font: {
      size: 8px;
      weight: 500!important;
    }
    &:hover {
      background-color: #888888;
    }
  }
  &-merchant {
    border: 1px solid #d1d1d1;
    padding: 4px;
    font-size: 11px;
    font-weight: 500;
  }
  &-success {
    color: #ffffff;
    background-color: $alert-success;
  }
  &-info {
    color: #ffffff;
    background-color: $alert-info;
  }
  &-danger {
    color: #ffffff;
    background-color: $alert-danger;
  }
  &-warning {
    color: #ffffff;
    background-color: $alert-warning;
  }
  &-unpublished {
    color: #ffffff;
    background-color: #b1b1b1;
  }
  &-inactive {
    color: #ffffff;
    background-color: #b1b1b1;
  }
  &-active {
    color: #ffffff;
    background-color: $alert-success;
  }
  &-onhold {
    color: #ffffff;
    background-color: $alert-warning;
  }
  &-suspended {
    color: #ffffff; 
    background-color: $alert-danger;
  }
  &-explicit {
    color: $alert-danger;
    display: inline-block;
    &:before {
			content: "\65";
			font-family: 'tunedicons';
		}
  }
  &-tag {
    color: #ffffff;
    font-size: 8px!important;
    background-color: #888888;
    text-transform: uppercase;
    margin: 0 1px;
    font: {
      size: 8px;
      weight: 500!important;
    }
    &:hover {
      background-color: #888888;
    }
  }
  &-nft {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-image: url('/images/nft_badge.png');
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.tag-badge {
  border-radius: 3px;
  margin: 3px 0 0 5px;
  font-weight: 500!important;
  color: #ffffff;
  padding: .10em .3em !important;
  font-size: 10px!important;
  line-height: 14px;
  background-color: #888888;
  text-transform: uppercase;
}

.token-badge {
  &-system {
    &:before {
      @extend .tag-badge;
      content: 'System Genre';
    }
  }
  &-general {
    &:before {
      @extend .tag-badge;
      content: 'General';
    }
  }
  &-genre {
    &:before {
      @extend .tag-badge;
      content: 'Genre';
    }
  }
  &-country {
    &:before {
      @extend .tag-badge;
      border-radius: 3px;
      content: 'Country';
    }
  }
  &-user {
    &:before {
      @extend .tag-badge;
      content: 'User';
    }
  }
  &-other {
    &:before {
      @extend .tag-badge;
      content: 'Other';
    }
  }
  
}