/******************************
*
*	Component: Content
*
******************************/

.gu-mirror {
  pointer-events: none;
}

#edit_bar_header {
  display: inline-block;
  margin: 0 0 5px 0;
  width: 100%;
}

.edit-bar {
  display: flex;
  flex-direction: column;
}

.content {
  &-title {
    font: {
      size: 1.25rem;
      weight: 500;
    }
    height: 25px;
    max-width: 60%;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.2;
    white-space: nowrap;
    display: inline-block;
  }
}

.editbar {
  &-btn {
    a {
      font-size: 14px;
      padding: 0 0 0 20px;
      color: #848484!important;
    }
    display: inline-block;
  }
}

.recent-content {
  margin: 0 0 10px 0;
}

.content-button {
  background-repeat: no-repeat;
  background-size: contain;
  padding-top: 100px;
  width: 125px;
  margin-bottom: 10px;
  position: relative;
  font-size: 1em!important;
  border: 0;
  span {
    &.fa {
      opacity: 0.5;
      position: absolute;
      left: 0;
      top: 0;
      background-color: #bebebe;
      width: 100%;
      height: 95px;
      padding-top: 13px;
      font-size: 4.5em;
      pointer-events: none;
    }
  }
  &-title {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    letter-spacing: 0px;
    font: {
      size: 14px;
      weight: 500;
    }
  }
}



#edit_bar {
  position: sticky;
  top: 0;
  z-index: 13;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  padding: 15px 20px;
  background-color: #FFF;
  border-radius: 4px;
  min-height: 100px;
}

.last-published {
  font-size: 11px;
  color: #888888;
  padding: 5px 0;
}

#publish_bar {
  border-radius: 0 0 4px 4px;
  bottom: 0;
  z-index: 11;
  padding: 10px 20px;
  background-color: rgba(250,250,250,0.9);
  min-height: 55px;
  box-shadow: 3px 0 15px rgba(0,0,0,.1);
  position: sticky;
  bottom: 0px;
}

.btn-toolbar {
  width: 100%;
  .btn {
    height: 35px;
    min-width: 39px;
    border-radius: 0.3rem;
  }
}

.dropdown {
  &-header {
    padding: .3rem 1rem;
    .menu-icon {
      width: 17px!important;
      display: inline-block;
    }
  }
  &-item {
    padding: .05rem 1rem;
    a {
      display: block;
    }
  }
  &-menu {
    max-height: 300px;
    height: auto;
    overflow-y: auto;
    overflow-x: auto;
    &::-webkit-scrollbar {
      -webkit-appearance: none;
    }
    &::-webkit-scrollbar:vertical {
      width: 11px;
    }
    &::-webkit-scrollbar:horizontal {
      height: 11px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      border: 2px solid white;
      background-color: rgba(0, 0, 0, .5);
    }
    &::-webkit-scrollbar-track { 
      background-color: #fff; 
      border-radius: 8px; 
    } 
  }
}

.content-row {
  padding: 1px;
  border-radius: 6px;
  border: 1px solid #FFF;
  position: relative;
  list-style: none;
  text-transform: initial;

  &.selected {
    border: 2px dotted #ffd5afc2;
    background: rgb(255 221 127 / 5%)!important;
    .group-name {
      background: rgb(255 221 127 / 5%)!important;
    }
  } 

  .form-group {
    margin-bottom: 8px;
    background: #f4f4f4;
    border-radius: 5px;
    padding: 15px;
  }

  .option {
    padding: 15px;
    border: 1px solid #cecece;
    border-radius: 5px;
  }

  .content-item {
    &.display-shelf,
    &.display-grid,
    &.display-list {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 150px;
      text-align: center;
      padding: 15px 20px;
      border-radius: 6px;
      border: 2px solid #D4D4D4;
      cursor: default;
      user-select: none;
    }
    &.display-group  {
      padding: 20px 5px 5px;
      background-color: transparent;
      text-align: left;
      border-radius: 6px;
      border: 3px dashed #f1f1f1;
      position: relative;
      margin: 5px 0 0;
      min-height: 50px;
      span.group-name {
        position: absolute;
        padding: 2px 8px;
        background-color: #ffffff;
        top: -9px;
        left: 5px;
        font-weight: 600;
        text-transform: uppercase;
        font-size: 10px;
        pointer-events: none;
      }
      &::before {
        text-transform: initial;
        text-align: center;
        width: 100%;
        top: 40%;
        bottom: 0;
        left: 0;
        right: 0;
        position: absolute;
        color: #dedede;
        font-size: 18px;
      } 
      span.group-priority {
        position: absolute;
        padding: 2px 8px;
        background-color: #ffffff;
        top: -9px;
        right: 5px;
        font-weight: 600;
        text-transform: uppercase;
        font-size: 10px;
        pointer-events: none;
      }
    }
  }

  .gu-transit {
    pointer-events: none;
  }

  .content-controls {
    transition: all .1s ease;
    visibility: hidden;
    position: absolute;
    z-index: 10;
    top: 5px;

    .btn.fa {
      padding: 5px;
      min-width: 29px;
    }

    &.left {
      left: 5px;
    }

    &.center { 
      left: 50px;
    }

    &.right {
      right: 5px;
    }

    button {
      font-size: 13px;
      padding: 5px;
    }
  }

  &:hover {
    border: 1px solid #CCC;

    .content-controls {
      visibility: visible;
    }
  }

  p[contenteditable="true"] {
    outline: none;
    text-indent: 10px;
    border-radius: 3px;
  }

  &.gu-transit {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
  }

  img {
    pointer-events: none
  }

  .content-tip-route {
    color: #FFF;
    background-color: rgba(0, 0, 0, 0.4);
    white-space: nowrap;
    position: absolute;
    top: 10px;
    right: 40px;
    padding: 0 10px;
    font-weight: 100;
  }

  .item_form {
    z-index: 12;
    display: none;
    position: absolute;
    top: 0px;
    left: 10px;
    right: 10px;
    text-align: left;
    box-shadow: 0 0 0px 2000px rgba(0, 0, 0, 0.4);
  }

  &.content-p {
    transition: all .2s ease;
    &:hover {
      padding: 38px 5px 5px;
    }
    ul {
      margin: 0 0 0 50px;
    }
    p {
      margin-bottom: 0px;
      min-width: 100%;
      min-height: 20px;
      cursor: text;
      display: inline-block;

      &:empty:after {
        content: 'Section (no translation)';
        color: red;
      }
    }
  }

  &.content-h1 {
    transition: all .2s ease;
    padding: 0 2px;
    &:hover {
      padding: 30px 5px 5px;
    }
    p {
      font: {
        size: 1.6em;
        weight: 400;
      }
      margin-bottom: 0px;
      width: 100%;
      min-height: 35px;
      cursor: text;
      display: inline-block;
      line-height: initial;
      &:empty:after {
        content: 'Heading (no translation)';
        color: red;
      }
    }
  }

  &.content-p button.fa-pencil {
    display: none;
  }

  &.content-ad_banner .content-item {
    position: relative;
    height: 95px;
    border: 2px solid #D4D4D4;
    background-color: #ffffff; 
    text-align: center;
    padding-top: 10px;
    border-radius: 6px;
    font-weight: 400;
    font-size: 14px;
    img {
      width: 190px;
    }
    .display-always {
      background: #747474;
      border-radius: 3px;
      text-transform: uppercase;
      color: #ffffff;
      font-size: 11px;
      padding: 1px 5px;
      position: absolute;
      top: 5px;
      right: 5px;
    }
  }

  &.content-spacing .content-item {
    height: 20px;
    background-color: transparent;
    text-align: center;
    border-radius: 6px;
    border: 1.5px dashed #e3e3e3;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
  }

  &.content-separator .content-item {
    position: relative;
    height: 22px;
    background-color: transparent;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    &:before {
      content: 'Separator Line';
      position: absolute;
      z-index: 1;
      left: 0;
      right: 0;
      top: 0;
      left: 0;
      margin: auto;
      padding: 0 10px;
      width: 130px;
      background-color: #ffffff;
    }
    &:after {
      content: '';
      position: absolute;
      height: 1px;
      width: 100%;
      background: #d4d4d4;
      top: 30px;
      margin: auto;
      top: 0; 
      left: 0; 
      bottom: 0; 
      right: 0;
    }
  }
}

.shelf {
  width: 100%;
  text-align: left;
  &-heading {
    font: {
      size: 20px;
      weight: 400;
    }
  }
  &-display,
  &-info,
  &-playlist {
    margin: 5px 0;
    line-height: 1.3;
    font: {
      size: 14px;
      weight: 400;
    }
    span {
      font-weight: 300;
    }
  }
}

.card-container {
  border-bottom: 1px solid rgba(0,0,0,.1);
  padding: 0 0 20px 0;
  margin: 0 0 20px 0;
  &:last-of-type {
    border-bottom: 0px;
    padding: 0;
    margin: 0;
  }
}

#bpm-range, #duration_range {
  margin: 0 10px;
}

#content_settings, #content_history {
  margin-top: -37px;
  transition: visibility 0s, height 0.3s;
  .card-body {
    p {
      margin-bottom: 0;
    }
    padding-bottom: 0;
  }
  .card {
    text-align: left;
    max-height: 400px;
    height: auto;
    overflow: scroll;
    margin: 0 0 10px 0;
    box-shadow: 0px 10px 15px rgba(0,0,0,.20);
    &::-webkit-scrollbar {
      -webkit-appearance: none;
    }
    &::-webkit-scrollbar:vertical {
      width: 11px;
    }
    &::-webkit-scrollbar:horizontal {
      height: 11px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      border: 2px solid white;
      background-color: rgba(0, 0, 0, .5);
    }
    &::-webkit-scrollbar-track { 
      background-color: #fff; 
      border-radius: 8px; 
    }
    &-container {
      display: block;
      width: 100%;
      border-bottom: 1px solid rgba(0,0,0,.125);
      padding: 0 0 20px 0;
      margin: 0 0 20px 0;
      &:last-of-type {
        border-bottom: 0px;
        padding: 0;
        margin: 0;
      }
    } 
  }
  .form-group {
    text-align: left;
  }
}

.verified-content {
  margin: 0 0 10px;
}

.tokenfield {
  cursor: default!important;
  pointer-events: none!important;
  .token-input {
    cursor: default!important;
  }
  &.focus {
    display: none!important;
  }
  &.form-control {
    border: 0px!important;
    padding: 0;
  } 
  .token {
    position: relative;
    border-radius: 25px;
    margin: 2px 5px 2px 0;
    padding: 0 5px 0;
    height: 27px;
    .close {
      pointer-events: all!important;
      font-size: 1.3em!important;
      top: 0px!important;
      line-height: 25px;
    }
    .token-label {
      padding: 3px 4px!important;
      font-size: 12px;
    }
  }
}

.form-group {
  position: relative;
}

.shelf-product {
  line-height: .9rem;
  padding: 5px 0 5px 37px;
  small {
    color: #747474;
    font-size: 9.5px;
  }
}

.content-emptystate {
  color: #6d757d;
  margin: 130px 0;
  .empty-state-icon {
    font-size: 35px;
    margin: 0 0 11px 0;  
  }
  .empty-state-title {
    font: {
      weight: 500;
      size: 1.25rem;
    }
  }
}

.play-button {
  z-index: 1;
  position: relative;
  float: left;
}

.time{
	text-align: right;
  font-size: 13px;
  font-weight: 400;
  position: absolute;
  z-index: 0;
  right: 11px;
  top: 15px;
  width: 100px;
}

.audio-progress{
	height: 100%;
  margin: 0 80px 0 50px;
  left: 0;
  right: 0;
  top: -2px;
  position: relative;
}

.audio-player {
  background: #ffffff;
  position: relative;
  height: 54px;
  border: 1px solid #eaeaea;
  margin-bottom: 30px;
  padding: 10px;
  position: relative;
  border-radius: 5px;
  box-shadow: 1px 1px 12px #d9d9d9;

  input[type=range] {
    background-color: #a3a3a3;
    height: 0;
    -webkit-appearance: none;
    width: 100%;
    border: 0;
  }

  input[type=range]:focus {
    outline: none;
  }

  input[type=range]::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    width: 100%;
    height: 7px;
    cursor: pointer;
    border-radius: 50px;
    border: 0px solid #FFFFFF;
    background-color: #a3a3a3;
  }

  input[type=range]::-webkit-slider-thumb {
    box-shadow: 1px 1px 3px #A3A3A3;
    border: 1px solid #ADADAD;
    height: 15px;
    width: 15px;
    border-radius: 50px;
    background: #FFFFFF;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -5px;
    transition: all .2s ease-in-out; 
  }

  input[type=range]:hover::-webkit-slider-thumb{
    transform: scale(1.5);
  }

  input[type=range]:focus::-webkit-slider-runnable-track {
    background: #333333;
  }

  input[type=range]::-moz-range-track {
    width: 100%;
    height: 10px;
    cursor: pointer;
    box-shadow: 0px 0px 0px #333333;
    background: #333333;
    border-radius: 50px;
    border: 0px solid #FFFFFF;
  }

  input[type=range]::-moz-range-thumb {
    box-shadow: 1px 1px 3px #A3A3A3;
    border: 1px solid #ADADAD;
    height: 18px;
    width: 18px;
    border-radius: 50px;
    background: #FFFFFF;
    cursor: pointer;
  }
  input[type=range]::-ms-track {
    width: 100%;
    height: 10px;
    cursor: pointer;
    border-color: transparent;
    color: transparent;
  }

  input[type=range]::-ms-fill-lower {
    background: #333333;
    border-radius: 100px;
  }

  input[type=range]::-moz-range-progress {
    background: #333333;
    border-radius: 100px;
  }

  input[type=range]::-moz-range-track {  
    background-color: #A3A3A3;
    border-radius: 100px;
  }

  input[type=range]::-ms-fill-upper { 
    background: #A3A3A3;
    border-radius: 100px;
  }

  input[type=range]::-ms-thumb {
    margin-top: 1px;
    box-shadow: 1px 1px 3px #A3A3A3;
    border: 1px solid #ADADAD;
    height: 18px;
    width: 18px;
    border-radius: 50px;
    background: #FFFFFF;
    cursor: pointer;
  }
  
  input[type=range]:focus::-ms-fill-lower {
    background: #333333;
  }

  input[type=range]:focus::-ms-fill-upper {
    background: #A3A3A3;
  }
  
}

.arrow-icon {
  transition: transform 0.3s ease;
}

.rotate-180 {
  transform: rotate(180deg);
}

.setting-block {
  margin: 15px 0;
  h6 {
    margin-bottom: 0;
  }
  p {
    color: #A8A8A8;
    font-size: 12px;
    margin-bottom: 5px!important;
  }
  &.bordered {
    border-radius: 5px;
    border: 1px solid #DADADA;  
    padding: 20px;
    color: #848484;
  }
  &.filled {
    border-radius: 5px;
    background: #F2F2F2;
    padding: 20px;
  }
  .form-check {
    margin: 10px 0;
  }
}

.content-value {
  padding: 10px;
  pointer-events: none;
}

#song-count {
  font-weight: 500;
  width: 200px;
}

.bar-container {
  width: 100%;
  background-color: #7c7c7c;
  border-radius: 4px;
  overflow: hidden;
  margin-top: 10px;
  position: relative;
}

.bar {
  height: 20px;
  background-color: #4caf50;
  text-align: center;
  color: white;
  line-height: 20px;
  width: 0;
  transition: width 0.5s ease;
}

.bar-label {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  font-size: 11px;
  text-align: center;
  line-height: 20px;
  color: #ffffff;
}