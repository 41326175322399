/******************************
*
*	Component: Modals
*
******************************/

#page_modal {
  z-index: 10000;
}

.modal {
  z-index: 10050;
  width: 100%;
  backdrop-filter: blur(3px);
  &-header {
    display: inline-block;
    padding: 1rem 1.2rem;
    border-bottom: 0;
    .close {
      padding: 0;
      right: 0;
      top: 0;
      margin: 15px!important;
      position: absolute;
      background: #d8d8d8;
      border-radius: 100%;
      height: 35px;
      width: 35px;
      span {
        color: #000000;
        position: relative;
        top: -2px;
        font: {
          size: 20px;
        }
      }
    }
  }
  &-body {
    margin: 0.2rem;
  }
  &-title { 
    max-width: 90%;
    .text-info {
      color: #31708f!important; 
    }
    &.muted {
      color: #C0C0C0;
      text-transform: uppercase; 
      font-size: 20px;
    }
  }
  &-backdrop {
    &.show {
      opacity: 0.6!important;
    }
  }
  &-footer {
    border-top: 0;
  }
}

@media (min-width: 992px) {
  .modal {
    &-lg {
      max-width: 700px;
    }
  }
}